import React, { useState } from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import colors from "../../assets/styles/colors";

// Images
import expo1 from "../../assets/images/expo-1-1.png";
import expo11 from "../../assets/images/expo-1-2.png";
import expo2 from "../../assets/images/expo-2-1.png";
import expo21 from "../../assets/images/expo-2-2.png";
import expo3 from "../../assets/images/expo-3-1.png";
import expo31 from "../../assets/images/expo-3-2.png";

import tick from "../../assets/images/tick-1.png";

import useBreakPoint from "@restart/hooks/useBreakpoint";

const FirstRow = () => {
  return (
    <Row style={{ marginTop: "10em" }}>
      <Col lg={{ span: 5 }} className="px-0">
        {/* <ScreenOne /> */}
        {/* <img src={screen1} style={{ marginLeft: "-15%", width: "100%" }} /> */}

        <img src={expo1} style={{ width: "70%" }} />
        <img
          src={expo11}
          style={{
            position: "absolute",
            width: "40%",
            bottom: 0,
            marginLeft: "-15%",
            zIndex: -1,
          }}
        />
      </Col>
      <Col lg={{ offset: 1 }} className="pt-5">
        <section style={s.text}>
          <p style={{ ...s.title, color: colors.orchid }}>
            Wie funktioniert maracuja genau?
          </p>
          <p>
            maracuja erkennt alle Freunde, mit denen Du über die Telefonnummer
            verbunden bist. So ermöglichen wir Euch das Teilen Eurer Vorhaben
            untereinander - mal spontan, mal geplant, mal verbindlich, mal
            unverbindlich. Je nachdem, wie Ihr es mögt. <br />
            <br /> In Deinem Kalender siehst Du die kommenden Events, zu denen
            Du zugesagt hast. Auch die, an denen du bereits teilgenommen hast,
            bleiben einsehbar.
          </p>

          <ul
            style={{
              listStyle: "none",
              padding: 0,
              marginTop: "40px",
            }}
          >
            <li style={s.list}>Automatische Verknüpfung Deiner Kontakte</li>
            <li style={s.list}>
              Ideale Kalenderansicht aller kommenden Events
            </li>
            <li style={s.list}>Favorisiere Freunde- und Familienlisten</li>
            <li style={s.list}>
              Treffe Dich mit alten Freunden und belebe so Deine Freizeit
            </li>
          </ul>
        </section>
      </Col>
    </Row>
  );
};
const SecondRow = () => {
  const isBig = useBreakPoint({ lg: "up" });

  return (
    <Row
      style={{ marginTop: isBig ? "-6vh" : "10vh" }}
      className="align-items-end"
    >
      <Col lg={{ span: 5, offset: 1, order: 2 }} className="px-0">
        <img src={expo2} style={{ width: "70%" }} />
        <img
          src={expo21}
          style={{
            position: "absolute",
            width: "40%",
            bottom: 0,
            marginLeft: "-15%",
          }}
        />
      </Col>
      <Col lg={{}} className="pt-5">
        <section style={s.text}>
          <p style={{ ...s.title, color: colors.goldenYellow }}>
            Auf die Plätze, fertig, los!
          </p>
          <p>
            Mit maracuja kannst Du sofort loslegen! Auf dem Home Screen kannst
            Du durch die Vorhaben Deiner Freunde swipen und direkt zusagen,
            sobald eine coole Idee vorgeschlagen wird.
          </p>
          <p>
            Mit einem Klick auf ein Event siehst Du Details, wie z.B. die
            Beschreibung, Ort und Datum, wer bereits zugesagt hat und was
            mitgebracht werden kann. Die Chat-Funktion ermöglicht Dir, auch
            direkt in der App Fragen zu stellen oder diese zu beantworten.
          </p>
          <p>
            Kein Hin- und Her-Versenden von Nachrichten mehr, wenn eine
            Aktivität mit Deinen Freunden geplant wird. Ihr habt das Wichtigste
            immer auf einem Blick. Ein ständiges Nachfragen wer mitkommt, wo man
            sich trifft oder wer was mitbringt, ist nicht mehr notwendig.
          </p>
        </section>
      </Col>
    </Row>
  );
};

const ThirdRow = ({ onImageLoad }) => {
  const isBig = useBreakPoint({ lg: "up" });

  return (
    <Row style={{ marginTop: "10em" }}>
      <Col lg={{ span: 5 }} className="px-0 justify-content-end">
        {/* <ScreenOne /> */}
        {/* <img src={screen1} style={{ marginLeft: "-15%", width: "100%" }} /> */}

        <img src={expo3} style={{ width: "70%" }} onLoad={onImageLoad} />
        <img
          src={expo31}
          style={{
            position: "absolute",
            width: "60%",
            bottom: 0,
            // marginLeft: "-25%",
            marginLeft: isBig ? 0 : "-25%",
            zIndex: 2,
            // left:'-30%'
            left: isBig ? "-30%" : "auto",
          }}
          // className="float-left"
        />
      </Col>
      <Col lg={{ offset: 1 }} className="pt-5">
        <section style={s.text}>
          <p style={{ ...s.title, color: colors.coralPink }}>
            Starte selbst ein unvergessliches Event!
          </p>
          <p>
            Mit maracuja ist es super einfach, Aktivitäten online vorzuschlagen
            und offline zu erleben. Deine Freunde und Bekannten können sich die
            Event-Details ansehen, wie Datum und Ort, wer zugesagt hat oder was
            mitgebracht werden soll. Und bei einer Zusage kann jeder direkt
            mitplanen.
          </p>
          <p>
            Ob Ihr gemeinsam mit dem Hund Gassi gehen wollt oder größere
            Teamveranstaltungen plant: maracuja macht’s möglich!
          </p>
        </section>
      </Col>
    </Row>
  );
};

const LastRow = ({ height }) => {
  const isBig = useBreakPoint({ lg: "up" });

  return (
    <Row
      style={{
        marginTop: isBig ? `-${height / 2}px` : "10px",
        backgroundColor: colors.coralPink,
        width: "100vw",
        borderBottomRightRadius: 110,
        height: isBig ? `${height / 2 + 40}px` : "auto",
      }}
      className="justify-content-sm-center px-sm-2"
    >
      <Col
        lg={{ offset: 4, span: 4 }}
        sm={{ span: 10 }}
        style={{
          paddingTop: "40px",
          ...styles.montserratRegular,
          fontSize: "0.91em",
          color: "white",
        }}
      >
        <p>
          Stell Dir eine Welt vor, in der Menschen ohne Barrieren einander
          begegnen und tolle Erlebnisse gemeinsam gestalten. Eine Welt, die es
          früher schon einmal gegeben hat - vor Facebook, Instagram und Co.
        </p>
        <p>
          maracuja nutzt die digitalen Möglichkeiten für das reale Leben, damit
          digitale Beziehungen auch wieder eine menschliche Komponente bekommen.
        </p>
        <p>
          Damit man nie wieder alleine durch die Stories der anderen scrollen
          muss, sondern gemeinsam neue Stories des Lebens schreiben kann.
        </p>
      </Col>
    </Row>
  );
};
export default function AppExpo() {
  const [height, setHeight] = useState(0);

  const onImageLoad = ({ target: img }) => {
    setHeight(img.offsetHeight);
    // this.setState({
    //   dimensions: { height: img.offsetHeight, width: img.offsetWidth },
    // });
  };

  // console.log(height);
  return (
    <section>
      <Container>
        <FirstRow />
        <SecondRow />
        <ThirdRow onImageLoad={onImageLoad} />
      </Container>
      <Container fluid>
        <LastRow height={height} />
      </Container>
    </section>
  );
}

const s = {
  introContainer: {
    borderTopRightRadius: 160,
    overflow: "hidden",
    ...styles.shadowLight,
  },
  introTextBox: {
    display: "flex",
    height: "15em",
    paddingLeft: "3em",
    paddingRight: "3em",
    ...styles.montserratRegular,
    fontSize: "1em",
    color: "white",
  },

  //   Description Cards
  cardImage: { ...styles.shadowLight, width: "100%" },
  cardTextBox: {
    ...styles.bg_purple,
    ...styles.montserratLight,
    fontSize: "0.8em",
    display: "flex",
    height: "9em",
    paddingTop: "1.3em",
    paddingRight: "0.8em",
    paddingLeft: "1.3em",
    color: "white",
    borderBottomLeftRadius: 43,
  },

  title: {
    ...styles.montserratRegular,
    fontSize: "1.7em",
    lineHeight: "1.2em",
  },
  text: {
    ...styles.montserratRegular,
    fontSize: "0.9em",
    lineHeight: "1.6em",
    color: colors.brownishGrey,
  },
  // List
  list: {
    backgroundImage: `url(${tick})`,
    backgroundPositionX: "left",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px",
    // left center no-repeat
    paddingLeft: "30px",
    marginBottom: "15px",
  },
};
