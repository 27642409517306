import React from "react";
import colors from "../assets/styles/colors";
import HomeJumbotron from "../components/home/HomeJumbotron";
import AppDescription from "../components/home/AppDescription";
import AppAbout from "../components/home/AppAbout";
import AppFeatures from "../components/home/AppFeatures";
import Footer from "../components/shared/footer";
import AppEventCreation from "../components/home/AppEventCreation";
import AppExpo from "../components/home/AppExpo";
import AppBenefit from "../components/home/AppBenefit";

const HomeView = () => {
  return (
    <div>
      {/* Jumbotron */}
      <HomeJumbotron />
      {/* App Short Description */}
      <AppDescription />

      {/* Content */}
      <AppAbout />

      {/* Show case */}
      {/* <AppShowcase /> */}
      {/* <DescriptionWrapper containerStyle={{ marginTop: "7em" }} /> */}

      {/* App Expo */}
      <AppExpo />
      {/* Features */}
      <AppFeatures />

      {/* Event Creation */}
      <AppEventCreation />
      {/* <AppScreenWrapper containerStyle={{ marginTop: "9.5em" }} /> */}

      {/* App Benefit  */}
      <AppBenefit />

      {/* Footer */}
      <Footer color={colors.orchid} />
    </div>
  );
};

export default HomeView;
