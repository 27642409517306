import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import colors from "../../assets/styles/colors";

// Images
// import calendarImg from "../../assets/images/calendar.png";
import iconCalendar from "../../assets/icons/icon-calendar.svg";
import iconArgument from "../../assets/icons/icon-argument.svg";
import iconSpeak from "../../assets/icons/icon-speak.svg";
import iconAnon from "../../assets/icons/icon-anon.svg";

export default function SocialFeatures() {
  const features = [
    {
      image: iconCalendar,
      text:
        "Wir sind oft zu beschäftigt und wollen uns nicht verbindlich festlegen.",
    },
    {
      image: iconArgument,
      text: "Organisationschaos in Gruppen mit mehreren Personen",
    },
    {
      image: iconSpeak,
      text: "Wir scheuen uns, alte Freunde proaktiv und direkt anzuschreiben.",
    },
    {
      image: iconAnon,
      text: "Wir wissen nicht was unsere Freunde demnächst vorhaben.",
    },
  ];
  const renderItem = ({ image, text }) => {
    return (
      <Col>
        <div className="text-center" style={{ width: "80%" }}>
          <img
            src={image}
            style={{
              width: "5em",
              height: "5em",
              marginBottom: "2em",
              // alignSelf: "center",
              // display: "block",
            }}
          />
          <p
            style={{
              textAlign: "center",
              ...styles.montserratRegular,
              fontSize: "0.9em",
              color: colors.brownishGrey,
            }}
          >
            {text}
          </p>
        </div>
      </Col>
    );
  };

  return (
    <section>
      <Container style={{ marginTop: "10em", marginBottom: "8em" }}>
        <Row>
          <Col>
            <Row
              className="justify-content-center"
              style={{ marginBottom: "5em" }}
            >
              <p
                style={{
                  alignSelf: "center",
                  ...styles.montserratRegular,
                  color: colors.brownishGrey,
                  fontSize: "1.7em",
                }}
              >
                Hürden der Kommunikation, die wir beseitigen wollen
              </p>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {features.map((e) => renderItem(e))}
        </Row>
      </Container>
    </section>
  );
}

const s = {
  introContainer: {
    borderTopRightRadius: 160,
    overflow: "hidden",
    ...styles.shadowLight,
  },
  introTextBox: {
    display: "flex",
    height: "15em",
    paddingLeft: "3em",
    paddingRight: "3em",
    ...styles.montserratRegular,
    fontSize: "1em",
    color: "white",
  },

  //   Description Cards
  cardImage: { ...styles.shadowLight, width: "100%" },
  cardTextBox: {
    ...styles.bg_purple,
    ...styles.montserratLight,
    fontSize: "0.8em",
    display: "flex",
    height: "9em",
    paddingTop: "1.3em",
    paddingRight: "0.8em",
    paddingLeft: "1.3em",
    color: "white",
    borderBottomLeftRadius: 43,
  },
};
