import React from "react";
import styles from "../../assets/styles/module.styles";

import { ModalFooter, Container, Row, Col, Nav } from "react-bootstrap";
export default function Footer({ color }) {
  return (
    <footer>
      <Container
        fluid
        style={{
          backgroundColor: color,
          paddingTop: "5em",
          paddingBottom: "5em",
        }}
      >
        <Row className="align-items-start justify-content-center">
          <Col className="text-center">
            <Nav.Link style={s.link} href="/">
              maracuja
            </Nav.Link>
            <Nav.Link style={s.link} href="/">
              Presse
            </Nav.Link>
          </Col>
          <Col className="text-center">
            <Nav.Link style={s.link} href="/">
              Be Social Movement
            </Nav.Link>
            <Nav.Link style={s.link} href="/datenschutzbestimmungen">
              Datenschutzbestimmungen
            </Nav.Link>
          </Col>

          <Col className="text-center">
            <Nav.Link style={s.link} href="/philosophy">
              Unternehmensphilosophie
            </Nav.Link>
            <Nav.Link style={s.link} href="/impressum">
              Impressum
            </Nav.Link>
            <Nav.Link style={s.link} href="/agb">
              AGB
            </Nav.Link>
          </Col>
          <Col className="text-center">
            <Nav.Link style={s.link} href="/team">
              Team
            </Nav.Link>
            <Nav.Link style={s.link} href="/kontakt">
              Kontakt
            </Nav.Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

const s = {
  link: {
    ...styles.montserratRegular,
    fontSize: "0.8em",
    color: "white",
  },
};
