import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import iosButton from "../../assets/images/kindpng_2552712.png";
import androidButton from "../../assets/images/kindpng_255271.png";
import logo from "../../assets/images/maracuja logo top.png";
import bg from "../../assets/images/phi-jumbo.jpg";
import Jumbotron from "../jumpbotron";
import colors from "../../assets/styles/colors";

export default function PhiJumbotron() {
  const LOGO_WRAPPER_SIZE = 80;
  const LOGO_WRAPPER_PADDING = 10;

  return (
    <div>
      <Jumbotron image={bg} className="align-items-end">
        <Container>
          <Row
            className="justify-content-lg-start justify-content-sm-center"
            style={{ paddingTop: "3em" }}
          >
            <Col lg={{ span: 11 }}>
              <p
                style={{
                  ...styles.montserratLight,
                  fontSize: "1.4em",
                  color: "white",
                }}
              >
                maracuja verbindet Freunde und Mitmenschen in der realen Welt
                miteinander.
              </p>
              <p
                style={{
                  ...styles.montserratRegular,
                  fontSize: "0.9em",
                  lineHeight: "1.7em",
                  color: "white",
                }}
              >
                Dank der sozialen Medien können wir mit Hunderten und manchmal
                Tausenden von Menschen online in Verbindung treten. maracuja ist
                die Verbindung zwischen Deinen Online- und Offline-Verbindungen.
                Wir nutzen die beste digitale Technologie, um Euch dabei zu
                unterstützen, Eure Freunde im wirklichen Leben wieder zu
                treffen.
              </p>
            </Col>
          </Row>
        </Container>

        <Row
          style={{
            position: "absolute",
            bottom: -(LOGO_WRAPPER_SIZE / 2),
            right: 0,
            left: 0,
          }}
          className="justify-content-center align-self-center"
        >
          <div
            style={{
              backgroundColor: "white",
              width: LOGO_WRAPPER_SIZE,
              height: LOGO_WRAPPER_SIZE,
              borderRadius: LOGO_WRAPPER_SIZE / 2,
              display: "flex",
            }}
            className="align-items-center justify-content-center"
          >
            <img
              src={logo}
              width={LOGO_WRAPPER_SIZE - LOGO_WRAPPER_PADDING * 2}
            />
          </div>
        </Row>
      </Jumbotron>
    </div>
  );
}
