import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import styles from "../assets/styles/module.styles";
import logo from "../assets/images/logo.png";
import colors from "../assets/styles/colors";
const Header = () => {
  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      expand="lg"
      style={{ backgroundColor: "#ffffff", opacity: 0.75 }}
    >
      <Navbar.Brand href="/">
        <img src={logo} height={40} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Nav>
            <Nav.Link style={s.link} href="/social">
              Be Social Movement
            </Nav.Link>
            <Nav.Link style={s.link} href="/philosophy">
              Unternehmensphilosophie
            </Nav.Link>
            <Nav.Link style={s.link} href="/team">
              Team & Kontakt
            </Nav.Link>
            <Nav.Link style={s.link} href="/fresh">
              Frisch Gepresst
            </Nav.Link>
          </Nav>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;

const s = {
  link: {
    ...styles.montserratMedium,
    fontSize: "0.7em",
    color: colors.brownishGrey,
  },
};
