import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import iosButton from "../../assets/images/kindpng_2552712.png";
import androidButton from "../../assets/images/kindpng_255271.png";
import logo from "../../assets/images/maracuja logo top.png";
import bg from "../../assets/images/start.jpg";
import Jumbotron from "../jumpbotron";
import colors from "../../assets/styles/colors";

import introImg1 from "../../assets/images/social-intro-1.png";
import introImg2 from "../../assets/images/social-intro-2.png";

export default function SocialIntro() {
  return (
    <section>
      <Container
        fluid
        style={{
          borderTopRightRadius: 160,
          borderBottomLeftRadius: 160,
          overflow: "hidden",
          ...styles.shadowVeryLight,
        }}
      >
        <Row
          style={{
            backgroundImage: `url(${introImg1})`,
            backgroundPositionY: "top",
            backgroundSize: "cover",
          }}
        >
          <Col
            lg={{ span: 6 }}
            style={{
              backgroundColor: "rgba(1, 21, 29, 0.67)",
              backdropFilter: "blur(1px)",
              color: "white",
              ...s.text,
              paddingRight: "5em",
              paddingLeft: "5em",
              paddingTop: "5em",
              paddingBottom: "5em",
            }}
          >
            <p style={{ ...s.title }}>
              Schluss mit Einsamkeit - warum maracuja so wichtig ist!
            </p>
            <p>
              Einsamkeit und soziale Isolation sind zu einer Epidemie geworden.
              Denkst Du, wir übertreiben? Werfen wir einen Blick auf die
              aktuellen Forschungsdaten aus aller Welt:
            </p>

            <p>
              In Großbritannien fühlen sich 60% der 18- bis 34-Jährigen ziemlich
              oft einsam. In den USA fühlen sich 46% der Gesamtbevölkerung
              regelmäßig einsam. Und in Deutschland sieht mehr als die Hälfte
              der Bevölkerung Einsamkeit als großes Problem.
            </p>
            <p>
              Einige Forscher berichten, dass Einsamkeit für die Gesundheit
              gefährlicher sein kann als Fettleibigkeit. Es ist Zeit, etwas
              dagegen zu unternehmen und Menschen zu helfen, sich weniger einsam
              und enger verbunden zu fühlen.{" "}
            </p>
            <p>
              maracuja liebt Freundschaften, die man von Angesicht zu Angesicht
              genießen kann. Mit unserer Social Life App wollen wir
              Zugehörigkeit, tiefe Verbundenheit und mehr Wohlbefinden fördern.
            </p>
          </Col>
        </Row>
        <Row
          style={{
            backgroundImage: `url(${introImg2})`,
            backgroundPositionY: "top",
            backgroundSize: "cover",
            // height: "80vh",
            // width: "100vw",
            // borderTopRightRadius: 160,
            // backgroundColor: "blue",
            // display: "flex",
          }}
        >
          <Col
            lg={{ offset: 6, span: 6 }}
            style={{
              backgroundColor: "rgba(1, 21, 29, 0.67)",
              backdropFilter: "blur(1px)",
              ...s.text2,
              paddingRight: "5em",
              paddingLeft: "5em",
              paddingTop: "5em",
              paddingBottom: "5em",
            }}
          >
            <p style={{ ...s.title }}>Wir leben unsere Freundschaften!</p>
            <p>
              Bei maracuja vertiefen wir leidenschaftlich gerne unsere
              Freundschaften. Wir haben maracuja kreiert, weil wir unsere
              Freunde in der realen Welt vermisst haben - obwohl wir online
              jeden Tag von ihnen etwas sehen oder lesen. Wir sind alle mit
              unseren digitalen Geräten verbunden. Aber ironischerweise scheinen
              wir umso weniger Freunde im echten Leben zu treffen, je mehr wir
              online mit ihnen verbunden sind.
            </p>
            <p>
              Das Senden von Nachrichten untereinander über unsere Smartphones
              ist so einfach - wir tun es die ganze Zeit. Wenn es jedoch darum
              geht, sich für die einfachsten Dinge zu verabreden, ist es oft
              eine Herausforderung, Datum, Uhrzeit und Details zu koordinieren.
              Also fragten wir uns: Wie können wir digitale Technologien dafür
              einsetzen, um es Menschen zu ermöglichen, mehr Zeit mit ihren
              Freunden und Bekannten offline zu verbringen? Damit es wieder so
              einfach wird, sich zu verabreden wie es früher schon mal war!
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const s = {
  title: {
    ...styles.montserratRegular,
    fontSize: "1.6em",
    color: colors.goldenYellow,
    lineHeight: "1em",
    marginBottom: "1.4em",
  },
  text: {
    ...styles.montserratLight,
    fontSize: "1em",
    color: "white",
    lineHeight: "1.4em",
  },
  text2: {
    ...styles.montserratRegular,
    fontSize: "1em",
    color: "white",
    lineHeight: "2em",
  },
};
