import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import colors from "../../assets/styles/colors";

export default function SocialContent() {
  return (
    <section>
      <Container
        // fluid
        style={
          {
            // borderTopRightRadius: 160,
            // borderBottomLeftRadius: 160,
            // overflow: "hidden",
            // ...styles.shadowVeryLight,
          }
        }
      >
        <Row style={{ marginTop: "6em", marginBottom: "15em" }} className="justify-content-center">
          <Col
            lg={{span:10}}
            style={{
              ...styles.montserratRegular,
              color: colors.brownishGrey,
              fontSize: "1em",
              lineHeight: "1.6em",
            }}
          >
            <p
              style={{
                fontSize: "2em",
                marginBottom: "2em",
              }}
            >
              Jeder strebt nach Freundschaften, die durch "dick und dünn" gehen!
            </p>
            <p>
              Freundschaften und soziale Kontakte sind essenziell, um ein
              gesundes und glückliches Leben zu führen. Die meisten von uns
              verbringen viel Zeit online, chatten und sind aktiv auf Social
              Media. In unseren Online-Aktivitäten spiegelt sich die Sehnsucht
              nach sozialem Miteinander wieder - wenn auch unbewusst. Und obwohl
              wir heutzutage mehr Freunde denn je haben, zweifeln viele
              Wissenschaftler daran, ob diese Freundschaften wirklich tief sind.
            </p>

            <p>
              Wenn man an die Corona-Krise denkt, wird uns bewusst, dass uns die
              physikalische Distanz viel abverlangt hat. Das Bedürfnis, jemanden
              in den Arm nehmen zu können, seine Freunde zu besuchen oder
              einfach mal mit der Familie ein leckeres Eis essen zu gehen, wuchs
              in uns. Es wurde uns plötzlich klar, wie selbstverständlich wir
              diese soziale Nähe zuvor gesehen haben.
            </p>
            <p>
              Freunde tun gut. Und gute Beziehungen machen uns zu zufriedeneren,
              glücklicheren Menschen. Studien haben gezeigt: Das Risiko für
              psychische Erkrankungen und Herz-Kreislauf-Beschwerden wird
              minimiert, wenn wir enge soziale Kontakte pflegen. Psychologen
              wissen, wir sehen Probleme auch weniger bedrohlich - eben weil wir
              nicht alleine ist.
            </p>

            <p
              style={{
                fontSize: "1.7em",
                lineHeight: "2em",
                // marginBottom: "2em",
              }}
            >
              Heißt es also, dass gute Freunde für ein längeres Leben sorgen?
            </p>
            <p>
              Wissenschaftler beantworten dies mit einem klaren Ja!
              Vorausgesetzt, es handelt sich um echte Freunde und
              vertrauensvolle Beziehungen auf gleicher Ebene. Oft haben Freunde
              sogar eine wichtigere "lebensverlängernde Wirkung” als Familie
              oder Verwandte. Das liegt laut Wissenschaft daran, dass wir uns
              unsere Freunde aussuchen können. So können wir uns funktionierende
              Freundschaften individuell aufbauen.
            </p>

            <p>
              Wir alle streben nach einem Sinn im Leben, und wahre Freunde sind
              ein wesentlicher Bestandteil eines erfüllten Lebens. Das liegt
              auch daran, dass jeder von uns den Wunsch verspürt, eine wichtige
              Rolle in dem Leben einer anderen Person zu spielen.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
