import React from "react";
import logo from "../assets/images/maracuja_logo.png";
import { Jumbotron as Jumbo, Container, Row, Col } from "react-bootstrap";

const LOGO_WRAPPER_SIZE = 90;
const LOGO_WRAPPER_PADDING = 15;

const Jumbotron = ({
  image,
  fluid,
  hideLogo = false,
  height = "100vh",
  children,
  className = "align-items-end justify-content-center",
}) => {
  const LOGO_WRAPPER_SIZE = 80;
  const LOGO_WRAPPER_PADDING = 10;

  return (
    <div>
      <Jumbo
        fluid={fluid}
        style={{
          backgroundImage: `url(${image})`,
          backgroundPositionY: "center",
          backgroundSize: "cover",
          height: height,
          width: "100vw",
          borderBottomLeftRadius: 160,
          // backgroundColor: "blue",
          display: "flex",
        }}
        className={className}
      >
        {children}
      </Jumbo>
    </div>
  );
};

export default Jumbotron;
