import React from "react";
import Footer from "../components/shared/footer";
import colors from "../assets/styles/colors";
import PhiJumbotron from "../components/philosophy/PhiJumbotron";
import PhiContent from "../components/philosophy/PhiContent";
import PhiBanner from "../components/philosophy/PhiBanner";
import PhiFeatures from "../components/philosophy/PhiFeatures";
import PhiBigBanner from "../components/philosophy/PhiBigBanner";

const PhiView = () => {
  return (
    <div style={{ flex: 1 }}>
      {/* Jumbotron */}
      <PhiJumbotron />
      {/* Content */}
      <PhiContent />
      {/* Banner */}
      <PhiBanner />
      {/* Features */}
      <PhiFeatures />
      {/* Big Banner */}
      <PhiBigBanner />
      {/* Footer */}
      <Footer color={colors.coralPink} />
    </div>
  );
};

export default PhiView;
