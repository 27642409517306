import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import iosButton from "../../assets/images/kindpng_2552712.png";
import androidButton from "../../assets/images/kindpng_255271.png";
import logo from "../../assets/images/maracuja logo top.png";
import bg from "../../assets/images/social-jumbo.jpg";
import Jumbotron from "../jumpbotron";
import colors from "../../assets/styles/colors";

export default function SocialJumbotron() {
  const LOGO_WRAPPER_SIZE = 80;
  const LOGO_WRAPPER_PADDING = 10;

  return (
    <div>
      <Jumbotron fluid image={bg}>
        <Row
          style={{
            height: "90%",
            width: "100%",
            paddingLeft: "2em",
            paddingRight: "2em",
          }}
        >
          <Col className="align-self-start">
            <span
              style={{
                ...styles.montserratRegular,
                fontSize: "1.8em",
                color: "white",
              }}
            >
              Wir wollen
              <br />{" "}
              <span
                style={{
                  ...styles.montserratBold,
                }}
              >
                echte Erlebnisse
              </span>
            </span>
          </Col>
          <Col className="align-items-end d-flex justify-content-end">
            <span
              style={{
                ...styles.montserratRegular,
                fontSize: "1.8em",
                color: "white",
              }}
            >
              und keine
              <br />{" "}
              <span
                style={{
                  ...styles.montserratBold,
                }}
              >
                virtuellen Likes!
              </span>
            </span>
          </Col>
        </Row>
        <Row
          style={{
            position: "absolute",
            bottom: -(LOGO_WRAPPER_SIZE / 2),
            width: "100vw",
          }}
          className=" justify-content-center align-self-center"
        >
          <div
            style={{
              backgroundColor: "white",
              width: LOGO_WRAPPER_SIZE,
              height: LOGO_WRAPPER_SIZE,
              borderRadius: LOGO_WRAPPER_SIZE / 2,
              display: "flex",
            }}
            className="align-items-center justify-content-center"
          >
            <img
              src={logo}
              width={LOGO_WRAPPER_SIZE - LOGO_WRAPPER_PADDING * 2 + 7}
            />
          </div>
        </Row>
      </Jumbotron>
      <Container>
        <Row className="justify-content-center">
          <Col lg={{ span: 6 }}>
            <div
              style={{
                display: "flex",
                marginTop: "2em",
                marginBottom: "3em",
                ...styles.montserratRegular,
                fontSize: "1em",
                lingHeight: "1.5em",
                textAlign: "center",
                color: colors.brownishGrey,
              }}
              className="justify-content-sm-center row"
            >
              <p className="align-self-center">
                Es ist schlimm, erst dann zu merken, dass man keine Freunde hat,
                wenn man Freunde nötig hat.
              </p>
              <p
                style={{
                  marginTop: "1em",
                  fontSize: "0.8em",
                }}
              >
                - Plutarch, Schriftsteller & Philosoph -
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
