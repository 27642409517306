import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import iosButton from "../../assets/images/kindpng_2552712.png";
import androidButton from "../../assets/images/kindpng_255271.png";
import logo from "../../assets/images/maracuja logo top.png";
import bg from "../../assets/images/start.jpg";
import Jumbotron from "../jumpbotron";
import colors from "../../assets/styles/colors";

export default function HomeJumbotron() {
  const LOGO_WRAPPER_SIZE = 80;
  const LOGO_WRAPPER_PADDING = 10;

  return (
    <div>
      <Jumbotron fluid image={bg}>
        <Row className="justify-content-center">
          <Col
            lg={{ span: 8 }}
            style={{
              verticalAlign: "center",
              textAlign: "center",
            }}
          >
            <div>
              <span style={s.title}>
                Lust, mal wieder in die reale Welt abzutauchen?
              </span>
              <p style={s.text}>
                Mit maracuja wird Freunde treffen wieder zum Kinderspiel.
                Schluss mit elend langen WhatsApp Diskussionen, unverbindlichen
                Zusagen und Gruppenchaos.
              </p>
            </div>

            <Row
              style={{
                marginTop: 46,
              }}
              className="justify-content-center"
            >
              <Col lg={{ span: 3 }}>
                <img src={iosButton} height={"46em"} />
              </Col>
              <Col lg={{ span: 3 }}>
                <img src={androidButton} height={"46em"} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          style={{
            position: "absolute",
            bottom: -(LOGO_WRAPPER_SIZE / 2),
          }}
          className=" justify-content-center align-self-center"
        >
          <div
            style={{
              backgroundColor: "white",
              width: LOGO_WRAPPER_SIZE,
              height: LOGO_WRAPPER_SIZE,
              borderRadius: LOGO_WRAPPER_SIZE / 2,
              display: "flex",
            }}
            className="align-items-center justify-content-center"
          >
            <img
              src={logo}
              width={LOGO_WRAPPER_SIZE - LOGO_WRAPPER_PADDING * 2}
            />
          </div>
        </Row>
      </Jumbotron>

      <Container
        style={{
          marginTop: "3em",
          marginBottom: "3em",
        }}
      >
        <Row className="justify-content-center text-center">
          <Col>
            <span
              style={{
                // display: "flex",

                ...styles.montserratRegular,
                fontSize: "1.3em",
              }}
              className="align-self-center"
            >
              Eine App, die das{" "}
              <span style={{ color: colors.barneyPurple }}>ECHTE</span> Leben
              verändert!
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const s = {
  title: {
    ...styles.montserratBold,

    fontSize: "1.5em",

    color: "white",
  },
  text: {
    fontSize: "0.9em",
    color: "white",
    ...styles.montserratLight,
  },
};
