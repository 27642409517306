import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import styles from "../assets/styles/module.styles";
import bg from "../assets/images/agb-1.jpg";

import TeamMembers from "../components/team/TeamMembers";
import TeamContactForm from "../components/team/TeamContactForm";
import Footer from "../components/shared/footer";
import colors from "../assets/styles/colors";
import Jumbotron from "../components/jumpbotron";

const Legals = () => {
  return (
    <Container>
      <Row
        className="justify-content-center"
        style={{ paddingBottom: "6em", paddingTop: "5em" }}
      >
        <Col lg={{ span: 10 }} style={s.text}>
          <p style={s.title}>
            Nutzungsbedingungen der mara cuja UG (haftungsbeschränkt)
          </p>
          <p>
            Wir, die mara cuja UG (haftungsbeschränkt), Grüner Weg 119, 58644
            Iserlohn, Deutschland (im Folgenden „maracuja“ und „wir“), haben die
            Applikation maracuja (nachfolgend „maracuja“ und „App“) entwickelt,
            die unter www.maracuja.app sowie im Apple App Store und Google Play
            Store heruntergeladen oder online genutzt werden kann. maracuja ist
            ein Organisationstool zum Erstellen, Verwalten und Teilen von
            Aktivitäten/Veranstaltungen (im Folgenden auch „Events“). maracuja
            ermöglicht die digitale Organisation und Kommunikation von privaten
            und geschäftlichen Events. maracuja kann weltweit von Endgeräten und
            den meisten gängigen aktuellen Betriebssystemen schnell, vollständig
            und benutzerfreundlich bedient und genutzt werden.
          </p>
          <p style={s.title}>1. Geltungsbereich von maracuja</p>
          <p>
            1.1. Diese Nutzungsbedingungen gelten für Deine Nutzung von maracuja
            mit sämtlichen Inhalten, Funktionen, Diensten und Regeln für das
            Vertragsverhältnis zwischen Dir und uns.
          </p>
          <p>
            1.2. Für bestimmte Anwendungen innerhalb von maracuja können wir mit
            Dir zusätzliche Nutzungsbedingungen vereinbaren. Auf solche
            zusätzlichen Nutzungsbedingungen werden wir Dich aber rechtzeitig
            vor der Nutzung hinweisen.
          </p>
          <p>
            1.3. Wir behalten uns vor, auf maracuja zusätzliche Dienste für
            Verbraucher anzubieten. Diese Nutzungsbedingungen richten sich
            sowohl an Verbraucher, als auch Unternehmen. Verbraucher sind
            natürliche Personen, die ein Rechtsgeschäft zu einem Zwecke
            abschließen, welches weder ihrer gewerblichen noch ihrer
            selbständigen beruflichen Tätigkeit zugerechnet werden kann.
            Unternehmer sind natürliche oder juristische Personen oder
            rechtsfähige Personengesellschaften, die bei Abschluss eines
            Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen
            beruflichen Tätigkeit handeln.
          </p>
          <p style={s.title}>
            2. Vertragsgegenstand: Angebotsumfang von maracuja
          </p>
          <p>
            2.1. Gegenstand des Vertrags ist die unentgeltliche Nutzung der
            maracuja Applikation, die als mobile Applikation (nachfolgend "App")
            abgerufen werden kann. Sämtliche Daten werden in der Cloud
            gespeichert und können so von unterschiedlichen Endgeräten immer
            abgerufen werden. In welcher Form und auf welchen Endgeräten Du
            maracuja nutzen kannst, ist in den jeweiligen Downloadseiten
            beziehungsweise im Apple App Store und im Google Play Store
            abrufbar. maracuja stellt Dir die Nutzung unterschiedlicher
            Anwendungen zur Verfügung:
          </p>
          <p style={s.subtext}>
            Jeder angemeldete Nutzer hat sein eigenes Profil, das er persönlich
            gestalten kann. In jedem Nutzer-Profil befinden sich einige von uns
            vorinstallierte Tools und Features, die Dir dabei helfen Dich mit
            anderen Usern zu verbinden, Termine zu vereinbaren und zu planen
            sowie diese mit anderen auch außerhalb der App über weitere Kanäle,
            wie bspw. WhatsApp oder E-Mail, zu teilen.
          </p>
          <p>
            2.2. Wir bieten Dir mit maracuja eine Applikation, die auch die
            selbstständige Vernetzung von Nutzern untereinander ermöglicht.
            Dafür stellen wir Dir die technischen Voraussetzungen in Form der
            Applikationen bereit. Eine inhaltliche Beteiligung an der Vernetzung
            und Kommunikation erfolgt nicht durch uns.
          </p>
          <p>
            2.3. maracuja steht Dir 24 Stunden am Tag und 365 Tage pro Jahr mit
            einer Verfügbarkeit von 90 % im Jahresmittel (nachfolgend „SLA“) zur
            Nutzung zur Verfügung („Systemlaufzeit“). Werden Wartungsarbeiten
            erforderlich und steht maracuja deshalb nicht zur Verfügung, werden
            wir Dich hierüber nach Möglichkeit rechtzeitig informieren. Ausfälle
            von maracuja aufgrund von Wartungsarbeiten werden nicht auf die SLAs
            angerechnet. Wir sind nicht für internet-/netzbedingte Ausfallzeiten
            und insbesondere nicht für Ausfallzeiten verantwortlich, in denen
            maracuja aufgrund von technischen oder sonstigen Problemen, die
            nicht im Einflussbereich von uns liegen (z.B. höhere Gewalt,
            Verschulden Dritter u. a.), nicht zu erreichen ist.
          </p>
          <p>2.4 Die App ist als kostenfreie Version erhältlich.</p>
          <p style={s.title}>
            3. Registrierung, Vertragsschluss, Nutzung von maracuja:
          </p>
          <p style={s.subtext}>Rechte und Pflichten Besonderer Hinweis</p>
          <p>
            Um die Dienste von maracuja nutzen zu dürfen, musst Du mindestens 14
            Jahre alt sein bzw. das Mindestalter besitzen, welches in Deinem
            Land erforderlich ist, um unsere Angebote verwenden zu dürfen.
            Solltest Du nicht das Mindestalter besitzen, das für die Nutzung von
            maracuja und die damit verbundene Zustimmung unserer Bedingungen in
            Deinem Land erforderlich ist, ist eine Zustimmung in Deinem Namen
            durch Deine Eltern oder einen Erziehungsberechtigten notwendig.
          </p>
          <p>3.1 Download von maracuja</p>
          <p>
            Du kannst maracuja als mobile App für die meisten gängigen iOS
            Endgeräte im Apple App Store und die meisten gängigen Android Geräte
            im Google Play Store herunterladen.
          </p>
          <p>3.2 Registrierung und Zustandekommen des Vertrags</p>
          <p>
            3.2.1 Du kannst maracuja auch ohne Registrierung nutzen. Um jedoch
            alle Funktionen von maracuja nutzen zu können, musst Du Dich
            registrieren.
          </p>
          <p>3.2.2 Die Registrierung erfolgt über deine Mobilfunknummer.</p>
          <p>
            3.2.3 Für eine Registrierung bei maracuja musst Du die
            erforderlichen Daten wahrheitsgetreu und vollständig angeben, sofern
            diese nicht als freiwillige Angaben gekennzeichnet sind. Damit Du
            Deine Events mit deinen Freunden über die App teilen kannst und auch
            Events deiner Freunde sehen kannst, musst Du Deine Handynummer
            angeben. Im Anschluss an die Anmeldung übersenden wir Dir eine
            Bestätigung Deiner Anmeldung einen Verifizierungscode per SMS,
            welchen Du angeben musst, um den Registrierungsprozess erfolgreich
            abzuschließen.
          </p>
          3.3 Vertragsverhältnis
          <p>
            Mobile Apps: Bei den mobilen Apps kommt der Vertrag zwischen Dir und
            uns zustande, wenn Du auf der Produktbeschreibungsseite des
            jeweiligen App-Stores den "Installieren" Button anklickst sowie ggf.
            Dein jeweiliges Passwort eingibst.
          </p>
          <p>
            Ein Anspruch auf Abschluss eines Nutzungsvertrages besteht nicht.
            Wir können Deine Registrierungsanfrage jederzeit ohne Angabe von
            Gründen ablehnen. In diesem Fall löschen wir selbstverständlich alle
            von Dir bereits angegebenen Informationen und Daten.
          </p>
          <p>
            3.4 Du darfst alle kostenlosen Features von maracuja unentgeltlich
            nutzen. Wir behalten uns vor, die App in Zukunft kostenpflichtig
            anzubieten oder einzelne kostenpflichtige Funktionen zu integrieren.
            Für die entgeltliche Nutzung dieser kostenpflichtigen Angebote ist
            Dein vorheriges Einverständnis erforderlich. Aus der Verwendung des
            kostenfreien Angebots von maracuja, gehen für Dich keinerlei
            zukünftige Zahlungsverpflichtungen hervor.
          </p>
          <p>
            3.5 Du bist selbst für die Geheimhaltung des Passworts
            verantwortlich. Das bedeutet, dass Du Dein Passwort für den Zugang
            geheim halten musst, nicht weitergeben darfst, keine Kenntnisnahme
            durch Dritte duldest oder ermöglichst und die erforderlichen
            Maßnahmen zur Gewährleistung der Vertraulichkeit ergreifst. Bei
            einem Missbrauch oder Verlust dieser Angaben oder einem
            entsprechenden Verdacht bist Du verpflichtet, uns dies per E-Mail
            unter folgender Email-Adresse unverzüglich anzuzeigen:
            hello@maracuja.app
          </p>
          <p>3.7 Regeln zur Nutzung von Maracuja</p>
          <p>
            3.7.1 Bei der Nutzung von maracuja musst Du alle anwendbaren Gesetze
            und sonstigen Rechtsvorschriften der Bundesrepublik Deutschland
            beachten. Du darfst insbesondere keine Daten oder Inhalte, wie
            beispielsweise Texte, Bilder, Grafiken und Links einstellen und/oder
            verbreiten, die gegen Rechtsvorschriften verstoßen, die fremde
            Schutz- oder Urheberrechte oder sonstige Rechte Dritter verletzen.
            Du bist für die von Dir bereitgestellten Daten und Inhalte selbst
            verantwortlich. Wir überprüfen die Inhalte weder auf ihre
            Richtigkeit, noch auf Virenfreiheit oder auf virentechnische
            Verarbeitbarkeit hin.
          </p>
          <p>
            3.7.2 Du hast die Möglichkeit in Deinem Profil ein Profilbild sowie
            ggf. andere Inhalte hochzuladen. Vor dem Upload jedes Fotos bist Du
            verpflichtet sicherzustellen, dass Dir an dem Foto die
            ausschließlichen Nutzungsrechte zustehen und die öffentliche
            Zugänglichmachung des Bildes oder anderer Inhalte nicht gegen
            gesetzliche Vorschriften, die guten Sitten und/oder gegen Rechte
            Dritter verstößt.
          </p>
          <p>
            3.7.3 Du darfst keine Dateien mit Gewaltdarstellungen,
            pornografischen, diskriminierenden, beleidigenden, rassistischen,
            verleumderischen oder sonstigen rechtswidrigen Inhalten oder
            Darstellungen hochladen und/oder öffentlich zugänglich machen.
            Bilder oder Fotos mit anderen Personen als Dir dürfen nur dann auf
            maracuja eingestellt werden, wenn das Einverständnis dieser Personen
            vorliegt.
          </p>
          <p>
            3.7.4 Du und wir können eingestellte Bilder und Dateien jederzeit
            löschen oder ersetzen. Insbesondere sind wir berechtigt, Bilder oder
            Dateien auch ohne Vorankündigung zu entfernen, wenn und soweit sich
            konkrete Anhaltspunkte dafür ergeben, dass durch die
            Veröffentlichung auf maracuja gegen gesetzliche Vorschriften, die
            guten Sitten und/oder gegen Rechte Dritter verstoßen wird.
          </p>
          <p>
            3.7.5 Auf maracuja eingestellte Inhalte und Texte dürfen nicht ohne
            Zustimmung des Rechteinhabers kopiert, verbreitet oder anderweitig
            öffentlich zugänglich gemacht werden – es sei denn, dies ist
            gesetzlich gestattet.
          </p>
          <p>
            3.7.6 Belästigende Handlungen wie das Versenden von Kettenbriefen
            oder anzüglicher bzw. sexuell geprägter Kommunikation sind zu
            unterlassen.
          </p>
          <p>
            3.7.7 Es ist verboten, Angriffe auf die Funktionsfähigkeit von
            maracuja vorzunehmen, wie beispielsweise das massenhafte Versenden
            von E-Mails (SPAM) und Eventeinladungen sowie das massenhafte
            Erstellen von Events (SPAM), Hacking-Versuche, Brute-Force-Attacken,
            der Einsatz oder das Versenden von Spionage-Software, Viren und
            Würmern.
          </p>
          <p>
            3.7.8 Sofern Du gegen diese Regeln verstößt sind wir berechtigt,
            Dich zu verwarnen, temporär zu sperren oder gegebenenfalls sogar
            vollständig von der Nutzung von maracuja auszuschließen. Wir sind
            berechtigt, rechtswidrige Inhalte umgehend zu entfernen. Sollte ein
            wichtiger Grund vorliegen, behält sich maracuja das Recht vor,
            Deinen Vertrag zu beenden und Dein Nutzerkonto zu löschen.
            Wiederholte Verstöße gegen die Allgemeinen Geschäftsbedingungen
            trotz wiederholter Abmahnung durch maracuja oder vorsätzliche
            Falschangaben im Rahmen der Anmeldung / des Vertragsschlusses
            stellen wichtige Gründe dar, die maracuja zur Schließung Deines
            Kontos ermächtigen.
          </p>
          <p>3.8 Haftungsfreistellung</p>
          <p>
            3.8.1 Du stellst uns von sämtlichen Ansprüchen, einschließlich
            Schadensersatzansprüchen, frei, die andere Nutzer oder sonstige
            Dritte, einschließlich Behörden, gegen uns wegen einer Verletzung
            ihrer Rechte durch die von Dir auf maracuja eingestellten Inhalte
            geltend machen. Ferner stellst Du uns von sämtlichen Ansprüchen,
            einschließlich Schadensersatzansprüchen, frei, die andere Nutzer
            oder sonstige Dritte, einschließlich Behörden, gegen uns wegen der
            Verletzung ihrer Rechte durch die Nutzung von maracuja durch Dich
            geltend machen. Du übernimmst alle angemessenen Kosten,
            einschließlich der für die Rechtsverteidigung entstehenden
            angemessenen Kosten, die uns aufgrund einer Verletzung von Rechten
            Dritter durch Dich entstehen. Alle weitergehenden Rechte sowie
            Schadensersatzansprüche von uns bleiben unberührt. Du hast das Recht
            nachzuweisen, dass uns tatsächlich geringere Kosten entstanden sind.
          </p>
          <p>
            3.8.2 Die vorstehenden Pflichten gelten nur dann, soweit Du die
            betreffende Rechtsverletzung zu vertreten hast, das heißt, Du
            wissentlich oder willentlich die im Verkehr erforderliche Sorgfalt
            außer Acht gelassen hast.
          </p>
          <p>3.9 Identität von Nutzern</p>
          <p>
            Uns ist es technisch nicht mit Sicherheit möglich festzustellen, ob
            ein auf maracuja registrierter Nutzer tatsächlich die Identität hat,
            die er vorgibt zu haben. Wir können daher keine Gewährleistung für
            die richtige Identität der Nutzer übernehmen.
          </p>
          <p style={s.title}>
            4. Widerrufsbelehrung für Verbraucher; Widerrufsrecht
          </p>
          <p>
            4.1 Sofern sich der Nutzer bei komoot zu einem Zweck registriert,
            der weder seinem gewerblichen noch seiner selbständigen beruflichen
            Tätigkeit zugerechnet werden kann, gelten für ihn als Verbraucher im
            Sinne des Gesetzes (§ 13 Bürgerliches Gesetzbuch) die folgenden
            Bestimmungen:
          </p>
          <p>
            4.2 Du hast das Recht, binnen vierzehn (14) Tagen ohne Angabe von
            Gründen diesen Vertrag zu widerrufen.
          </p>
          <p>
            Die Widerrufsfrist beträgt vierzehn (14) Tage ab dem Tag des
            Vertragsschlusses. Um Dein Widerrufsrecht auszuüben, musst Du uns
            (mara cuja UG (haftungsbeschränkt), Grüner Weg 119, 58644 Iserlohn,
            Deutschland) mittels einer eindeutigen Erklärung (z. B. ein mit der
            Post versandter Brief oder per E-Mail an: hello@maracuja.app) über
            Deinen Entschluss, diesen Vertrag zu widerrufen, informieren. Zur
            Wahrung der Widerrufsfrist reicht es aus, dass Du die Mitteilung
            über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
            abschickst.
          </p>
          <p>
            4.3 Folgen des Widerrufs: Wenn Du diesen Vertrag widerrufst, haben
            wir Dir alle möglichen Zahlungen, die wir von Ihnen erhalten haben,
            unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag
            zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses
            Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden
            wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
            Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
            ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
            wegen dieser Rückzahlung Entgelte berechnet.
          </p>
          <p>
            4.4 Das Widerrufsrecht des Nutzers erlischt vorzeitig, wenn der
            Vertrag von beiden Seiten auf dem ausdrücklichen Wunsch des Nutzers
            vollständig erfüllt ist, bevor er sein Widerrufsrecht ausgeübt hat.
          </p>
          <p style={s.subtext}>— Ende der Widerrufsbelehrung —</p>
          <p style={s.title}>5. Verantwortlichkeit für Inhalte</p>
          <p>
            5.1 Wir übernehmen keinerlei Verantwortung für die von Dir und
            anderen maracuja-Nutzern eingegebenen Texte, bereitgestellten
            Inhalte, Bilder, Daten und/oder Informationen sowie für Inhalte auf
            verlinkten externen Websites. Wir gewährleisten insbesondere nicht,
            dass diese Inhalte wahr sind, einen bestimmten Zweck erfüllen oder
            einem solchen Zweck dienen können.
          </p>
          <p>
            5.2 Wenn Du eine rechts- oder vertragswidrige Benutzung von maracuja
            bemerkst oder vermutest, kannst Du diese jederzeit an uns melden
            unter folgender Email-Adresse: hello@maracuja.app.
          </p>
          <p style={s.title}>
            6. Laufzeit Deines Nutzungsvertrags und dessen Beendigung
          </p>
          <p>6.1 Unentgeltliche Nutzung von Maracuja</p>
          <p>
            6.1.1 Der Vertrag über die unentgeltliche Nutzung der maracuja App
            wird auf unbestimmte Zeit geschlossen.
          </p>
          <p>
            61.2 Du kannst den Vertrag jederzeit dadurch kündigen, indem Du,
            eine E-Mail schreibst (hello@maracuja.app) mit dem Hinweis Deinen
            Account vollständig zu löschen. Nicht gesicherte Daten gehen bei der
            Kündigung verloren, da wir Deinen Account vollständig löschen.
            Darauf weisen wir Dich vor der Löschung nicht nochmals gesondert
            hin.
          </p>
          <p>
            6.1.3 Wir können den Nutzungsvertrag mit einer Frist von zwei (2)
            Wochen schriftlich (E-Mail ist ausreichend) kündigen.
          </p>
          <p>
            6.2 Der Vertrag kann darüber hinaus von jeder Partei aus wichtigem
            Grund gekündigt werden. Ein wichtiger Grund, der die mara cuja UG
            (haftungsbeschränkt) zur Kündigung berechtigt, liegt insbesondere
            vor, wenn Du gegen vertragliche Pflichten verstößt, insbesondere
            gegen die in Ziffer 3.7 beschriebenen Nutzungsregeln von maracuja.
          </p>
          <p style={s.title}>7. Haftung</p>
          <p>
            Für die Nutzung der kostenlosen Bereiche von maracuja ist die
            Haftung von uns auf Vorsatz und auf grobe Fahrlässigkeit oder Fehlen
            einer garantierten Eigenschaft beschränkt. Bei Vorsatz haften wir in
            voller Höhe, bei grober Fahrlässigkeit und dem Fehlen einer
            garantierten Eigenschaft ist die Haftung auf die Höhe des typischen
            und vorhersehbaren Schadens beschränkt. Eine weitergehende Haftung
            ist ausgeschlossen.
          </p>
          <p style={s.title}>8. Kontaktaufnahme zu Nutzern</p>
          <p>
            8.1 Der Nutzer ist damit einverstanden, dass maracuja während der
            Benutzung der App den Nutzer kontaktieren darf um Angebote von
            maracuja an den Nutzer per E-Mail, Push-Nachrichten, telefonisch
            oder postalisch zu übermitteln.
          </p>
          <p>
            8.2 Zur Qualitätssicherung ist der Nutzer damit einverstanden, dass
            maracuja während der Benutzung der App und bis zu einem (1) Monat
            nach Löschung des Accounts zur Verbesserung und Erweiterung der
            Angebote von maracuja den Nutzer per E-Mail, telefonisch oder
            postalisch kontaktieren darf, um sich so über die Zufriedenheit mit
            den Angeboten von maracuja zu informieren.
          </p>
          <p style={s.title}>
            9. Anwendbares Recht, Gerichtsstand, Schlussbestimmungen und
            Änderungen der Nutzungsbedingungen
          </p>
          <p>9.1 Es gilt das Recht der Bundesrepublik Deutschland.</p>
          <p>
            9.2 Hast Du keinen allgemeinen Gerichtsstand in Deutschland oder in
            einem anderen EU-Mitgliedstaat oder hast Du Deinen festen Wohnsitz
            nach Wirksamwerden dieser Nutzungsbedingungen ins Ausland verlegt
            oder ist Dein Wohnsitz oder gewöhnlicher Aufenthaltsort im Zeitpunkt
            der Klageerhebung nicht bekannt, ist ausschließlicher Gerichtsstand
            für sämtliche Streitigkeiten aus diesem Vertrag der Sitz von uns.
          </p>
          <p>
            9.3 Sollten einzelne Bestimmungen dieser Nutzungsbedingungen
            unwirksam sein oder werden und/oder den gesetzlichen Regelungen
            widersprechen, so wird hierdurch die Wirksamkeit der
            Nutzungsbedingungen im Übrigen nicht berührt. Die unwirksame
            Bestimmung wird von den Vertragsparteien einvernehmlich durch eine
            solche Bestimmung ersetzt, welche dem wirtschaftlichen Zweck der
            unwirksamen Bestimmung in rechtswirksamer Weise am nächsten kommt.
            Die vorstehende Regelung gilt entsprechend bei Regelungslücken.
          </p>
          <p>
            9.4 Wir behalten uns vor, diese AGB mit Wirkung für die Zukunft zu
            ändern und anzupassen. Die jeweils aktuelle Version der
            Nutzungsbedingungen kannst Du per E-Mail an hello@maracuja.app
            anfordern oder auf www.maracuja.app abrufen. Du wirst spätestens
            einen Monat vor dem geplanten Inkrafttreten der neuen Fassung der
            Nutzungsbedingungen von uns auf die Änderungen hingewiesen.
            Widersprichst Du der Geltung der neuen Nutzungsbedingungen nicht
            innerhalb eines Monats nach Inkrafttreten, so gelten die neuen
            Nutzungsbedingungen als akzeptiert. Wir werden Dich auf die
            Bedeutung der Monatsfrist und des Widerspruchsrechts sowie die
            Rechtsfolgen des Schweigens gesondert in geeigneter Form hinweisen.
          </p>
          <p>
            Dieser Änderungsmechanismus gilt nicht für Änderungen der
            vertraglichen Hauptleistungspflichten der Parteien.
          </p>
          <p>— Ende der Nutzungbedingungen —</p>
        </Col>
      </Row>
    </Container>
  );
};

export default function AGBView() {
  return (
    <div style={{ flex: 1 }}>
      <Jumbotron image={bg} height="80vh" className="align-items-end">
        <Container>
          <Row className="justify-content-left">
            <Col className="offset-1">
              <span
                style={{
                  ...styles.montserratLight,
                  fontSize: "1.4em",
                  color: "white",
                }}
              >
                AGB
              </span>
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <Legals />
      {/* Footer */}
      <Footer color={colors.orchid} />
    </div>
  );
}

const s = {
  title: {
    ...styles.montserratSemiBold,
    color: colors.orchid,
    fontSize: "0.95em",
  },
  subtext: {
    ...styles.montserratRegular,
    color: colors.orchid,
    fontSize: "0.9em",
    lineHeight: "1.7em",
  },
  text: {
    ...styles.montserratRegular,
    color: colors.brownishGrey,
    fontSize: "0.9em",
    lineHeight: "1.7em",
  },
};
