import React from "react";
import SocialJumbotron from "../components/social/SocialJumbotron";
import SocialIntro from "../components/social/SocialIntro";
import SocialFeatures from "../components/social/SocialFeatures";
import SocialContentBanner from "../components/social/SocialContentBanner";
import SocialContent from "../components/social/SocialContent";
import Footer from "../components/shared/footer";
import colors from "../assets/styles/colors";

const BeSocialView = () => {
  return (
    <div style={{ flex: 1 }}>
      {/* Jumbotron */}
      <SocialJumbotron />
      {/* Intro */}
      <SocialIntro />
      {/* Features */}
      <SocialFeatures />
      {/* Banner */}
      <SocialContentBanner />
      {/* Content */}
      <SocialContent />
      {/* Footer */}
      <Footer color={colors.brownishGrey} />
    </div>
  );
};

export default BeSocialView;
