import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";

import introImg1 from "../../assets/images/start-2.png";
import introImg2 from "../../assets/images/desc-2.png";
import introImg3 from "../../assets/images/intro-2.png";

export default function AppDescription() {
  const LOGO_WRAPPER_SIZE = 80;
  const LOGO_WRAPPER_PADDING = 10;

  return (
    <section>
      <Container fluid>
        <Row style={s.introContainer} className="mb-5">
          <Col
            lg="6"
            style={{
              backgroundImage: `url(${introImg1})`,
              backgroundSize: "cover",
            }}
          />
          <Col
            lg="6"
            style={{
              ...styles.bg_purple,
              ...s.introTextBox,
              ...styles.montserratMedium,
            }}
            className="align-items-center"
          >
            <span>
              Erstelle und teile Deine Vorhaben & Aktivitäten mit Deinen
              Freunden. Wer Lust hat dabei zu sein, kann einfach mit einem Klick
              zusagen.
            </span>
          </Col>
          <Col
            lg="3"
            style={{
              backgroundImage: `url(${introImg2})`,
              backgroundSize: "cover",
            }}
          />
          <Col
            lg="3"
            style={{ ...styles.bg_yellow, ...s.introTextBox }}
            className="align-items-center"
          >
            <span>
              Oder swipe selbst durch die Events Deiner Freunde und unternehme
              etwas mit ihnen.
            </span>
          </Col>
          <Col
            lg="3"
            style={{
              backgroundImage: `url(${introImg3})`,
              backgroundSize: "cover",
            }}
          />

          <Col
            lg="3"
            style={{ ...styles.bg_red, ...s.introTextBox }}
            className="align-items-center"
          >
            <span>
              Schluss mit ewiger Planerei in diversen Gruppen und
              unverbindlichen Aussagen. Werde aktiv und freue Dich auf
              unvergessliche Erlebnisse mit Deinen Liebsten!
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const s = {
  introContainer: {
    borderTopRightRadius: 160,
    overflow: "hidden",
    ...styles.shadowLight,
  },
  introTextBox: {
    display: "flex",
    height: "30vh",
    paddingLeft: "3em",
    paddingRight: "3em",
    ...styles.montserratRegular,
    fontSize: "1em",
    color: "white",
  },

  //   Description Cards
  cardImage: { ...styles.shadowLight, width: "100%" },
  cardTextBox: {
    ...styles.bg_purple,
    ...styles.montserratLight,
    fontSize: "0.8em",
    display: "flex",
    height: "9em",
    paddingTop: "1.3em",
    paddingRight: "0.8em",
    paddingLeft: "1.3em",
    color: "white",
    borderBottomLeftRadius: 43,
  },
};
