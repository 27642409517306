import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import iosButton from "../../assets/images/kindpng_2552712.png";
import androidButton from "../../assets/images/kindpng_255271.png";
import logo from "../../assets/images/maracuja logo top.png";
import bg from "../../assets/images/start.jpg";
import Jumbotron from "../jumpbotron";
import colors from "../../assets/styles/colors";

import img1 from "../../assets/images/phi-1.jpg";
import img2 from "../../assets/images/phi-2.jpg";
import img3 from "../../assets/images/phi-3.png";

export default function PhiBanner() {
  return (
    <section>
      <Container
        fluid
        style={{
          borderTopRightRadius: 160,
          borderBottomLeftRadius: 160,
          overflow: "hidden",
        }}
      >
        <Row
          style={{
            backgroundImage: `url(${img1})`,
            backgroundPositionY: "center",
            backgroundSize: "cover",
            width: "100vw",
            // ...styles.shadow(0, 10, 43.5, 0, 0.35),
          }}
          className="justify-content-end pr-5"
        >
          <Col
            lg={{ span: 5 }}
            style={{ ...s.text }}
            className="align-self-center pt-5 pb-5"
          >
            <section>
              <p style={{ ...s.title }}>Warum maracuja?</p>
              <p>Die Maracuja Frucht steht als Symbol für das, was wir tun.</p>

              <p>
                Dabei symbolisieren die Kerne der Frucht unsere Freunde und
                zeigen, wie eng wir eigentlich verbunden sind.{" "}
              </p>

              <p>
                Das weiche Innere symbolisiert die Zerbrechlichkeit unserer
                Freundschaften. Um langfristig bestehen zu können, brauchen sie
                viel Liebe und Pflege.{" "}
              </p>

              <p>
                Die Schale der Maracuja trägt und stärkt das weiche Innere - so
                wie unsere App neue Möglichkeiten bietet, um tiefe
                Freundschaften in der realen Welt aufzubauen und aufrecht zu
                erhalten.{" "}
              </p>
            </section>
          </Col>
        </Row>
        <Row
          style={{
            backgroundImage: `url(${img2})`,
            backgroundPositionY: "50%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: "flex",
            paddingTop: "6em",
            paddingBottom: "6em",
          }}
          className="justify-content-start"
        >
          <Col lg={{ span: 3, offset: 1 }} className="align-self-center">
            <p style={{ ...s.title, fontSize: "1.41em", color: "white" }}>
              maracuja verschiebt Deinen Fokus ...
            </p>
            <p style={{ ...s.text, fontSize: "0.95em", color: "white" }}>
              … von dem Betrachten und Liken der Fotos Deiner Freunde und von
              Events, bei denen Du nicht dabei warst, auf die Gestaltung
              zukünftiger gemeinsamer Erlebnisse.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const s = {
  title: {
    ...styles.montserratRegular,
    fontSize: "1.4em",
    color: colors.coralPink,
  },
  text: {
    ...styles.montserratRegular,
    fontSize: "0.9em",
    color: colors.brownishGrey,
  },
};
