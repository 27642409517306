import React from "react";

import { Row, Col, Container, InputGroup, FormControl } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import { Slide } from "react-awesome-reveal";

import introImg1 from "../../assets/images/intro-1.png";
import introImg2 from "../../assets/images/intro-2.png";
import introImg3 from "../../assets/images/intro-3.png";
import introImg4 from "../../assets/images/intro-4.png";

import mailbox from "../../assets/images/email.svg";
import mailcaption from "../../assets/images/email-caption.svg";
import colors from "../../assets/styles/colors";

const CONTENT = [
  {
    image: introImg1,
    color: colors.orchid,
    text: "...sich mit Freunden einfacher zu verabreden",
  },
  {
    image: introImg2,
    color: colors.coralPink,
    text: "...coole Events zu organisieren oder gemeinsamen Hobbys nachzugehen",
  },
  {
    image: introImg3,
    color: colors.goldenYellow,
    text: "… gemeinsam Veranstaltungen zu besuchen",
  },
  {
    image: introImg4,
    color: colors.brownishGrey,
    text: "… Lerngruppen in der Uni oder Schule zu organisieren",
  },
];

const EmailForm = () => {
  return (
    <Slide triggerOnce direction="right">
      <Row
        style={{
          ...s.emailContainer,
          backgroundImage: `url(${mailbox})`,
          backgroundPositionY: "center",
          backgroundPositionX: "100%",
          backgroundSize: "auto 80%",
          backgroundRepeat: "no-repeat",
          // width:'100vw'
        }}
      >
        <Col lg={{ span: 8 }} sm={{ span: 8 }}>
          <p
            style={{
              ...styles.montserratSemiBold,
              color: colors.coralPink,
              fontSize: "1.2em",
            }}
          >
            Verpasse keine fruchtigen News mehr!
          </p>
          <p
            style={{
              ...styles.montserratLight,
              color: colors.coralPink,
              fontSize: "0.9em",
              marginTop: "1.7em",
            }}
          >
            Melde dich jetzt bei unserem Newsletter an. Keine Sorge - Spam
            wird's nicht geben! Wir bringen Dich einfach auf den neusten Stand!
          </p>

          {/* <img src={mailbox} style={{}} /> */}
          <InputGroup
            className="mb-3"
            style={{ width: "80%", marginTop: "2em" }}
          >
            <FormControl
              style={{
                borderStyle: "dashed",
                borderColor: colors.coralPink,
                color: colors.coralPink,
              }}
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <img src={mailcaption} width={"40%"} style={{ marginLeft: "3em" }} />
        </Col>
      </Row>
    </Slide>
  );
};

export default function AppAbout() {
  return (
    <section>
      <Container style={{ marginTop: "8em" }}>
        {/* About */}
        <Row
          className="justify-content-center align-items-center mb-sm-5 "
          style={{
            color: colors.brownishGrey,
          }}
        >
          {/* Content */}
          <Col lg={{ span: 5 }}>
            <p
              style={{
                ...styles.montserratRegular,
                fontSize: "1.4em",
              }}
            >
              Unkompliziert. Schnell. Für jedermann.
            </p>
            <div
              style={{
                ...styles.montserratRegular,
                fontSize: "0.9em",
                lineHeight: "1.6em",
              }}
            >
              <br />
              <p>
                maracuja ist eine Technologie mit Herz und Seele, die Freunde
                wieder zusammenbringt. Es hat sich gezeigt: Wenn Menschen sich
                im wirklichen Leben treffen, wird ihre Beziehung enger und ihre
                Freundschaft wächst.
              </p>

              <p>
                maracuja hilft Dir dabei, Deine Freundschaften zu vertiefen und
                sie live und in Farbe zu erleben. Es ist Dein Smart Link
                zwischen dem digitalen und dem realen Leben.
              </p>
              <p>
                Und das Beste daran ist: maracuja macht es Dir leicht,
                Aktivitäten und Events gemeinsam zu organisieren und zu planen.
              </p>
              <br />
            </div>
            <p style={{ ...styles.montserratSemiBold, fontSize: "0.9em" }}>
              Unser Ziel ist es, Treffen unter Freunden so einfach wie möglich
              zu gestalten. maracuja ist ideal um ...
            </p>
          </Col>
          {/* Email */}
          <Col lg={{}} className="mx-sm-3 mr-lg-0 ml-lg-5">
            <EmailForm />
          </Col>
        </Row>
        {/* Cards */}
        <Row className="justify-content-center">
          <Col lg={{}}>
            <Row>
              {CONTENT.map((c) => {
                return (
                  <Col sm={{ span: 12 }} lg={{ span: 3 }} className="mb-sm-3">
                    <img src={c.image} style={s.cardImage} />
                    <div style={{ ...s.cardTextBox, backgroundColor: c.color }}>
                      <span>{c.text}</span>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
          {/* <Col
            lg={{ span: 10 }}
            className="d-flex justify-content-around"
            style={{ marginTop: "4em" }}
          >
            <div style={{ marginRight: "5em" }}>
              <img src={introImg2} style={s.cardImage} />
              <div style={s.cardTextBox}>
                <span>...sich mit Freunden einfacher zu verabreden</span>
              </div>
            </div>
            <div style={{ marginRight: "5em" }}>
              <img src={introImg2} style={s.cardImage} />
              <div style={{ ...s.cardTextBox, ...styles.bg_red }}>
                <span>
                  ...coole Events zu organisieren oder gemeinsamen Hobbys
                  nachzugehen
                </span>
              </div>
            </div>
            <div style={{ marginRight: "5em" }}>
              <img src={introImg2} style={s.cardImage} />
              <div style={{ ...s.cardTextBox, ...styles.bg_yellow }}>
                <span>… gemeinsam Veranstaltungen zu besuchen </span>
              </div>
            </div>
            <div>
              <img src={introImg2} style={s.cardImage} />
              <div style={{ ...s.cardTextBox, ...styles.bg_dark }}>
                <span>
                  … Lerngruppen in der Uni oder Schule zu organisieren
                </span>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
}

const s = {
  emailContainer: {
    // overflow: "hidden",
    paddingTop: "2em",
    paddingLeft: "2em",
    paddingBottom: "1em",
    // borderBottomLeftRadius: 16,
    ...styles.shadowMedium,
    borderRadius: 16,
  },

  introContainer: {
    borderTopRightRadius: 160,
    overflow: "hidden",
    ...styles.shadowLight,
  },
  introTextBox: {
    display: "flex",
    height: "15em",
    paddingLeft: "3em",
    paddingRight: "3em",
    ...styles.montserratRegular,
    fontSize: "1em",
    color: "white",
  },

  //   Description Cards
  cardImage: { ...styles.shadowLight, width: "100%" },
  cardTextBox: {
    ...styles.bg_purple,
    ...styles.montserratLight,
    fontSize: "0.8em",
    display: "flex",
    height: "9em",
    paddingTop: "1.3em",
    paddingRight: "0.8em",
    paddingLeft: "1.3em",
    color: "white",
    borderBottomLeftRadius: 43,
  },
};
