import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import colors from "../../assets/styles/colors";

// Images
import calendarHome from "../../assets/images/calendar-s.png";

// Icons
import iconList from "../../assets/icons/business-and-finance.png";
import iconMic from "../../assets/icons/microphone.png";
import iconImage from "../../assets/icons/gallery.png";
import iconMarker from "../../assets/icons/pin.png";
import iconSwipe from "../../assets/icons/swipe.png";

export default function AppFeatures() {
  const features = [
    {
      color: colors.orchid,
      image: iconList,
      text:
        "Organisationmodule, wie Checklisten, Erinnerungsfunktion oder das Einbinden in Deinen persönlichen Kalender, vereinfachen die Planung von Events.",
    },
    {
      color: colors.coralPink,
      image: iconSwipe,

      text:
        "Freunde können per Swipe Events durchsuchen, sich inspirieren lassen und bei Interesse direkt zusagen.",
    },
    {
      color: colors.goldenYellow,
      image: iconMic,

      text:
        "Einfaches Teilen eigener Vorhaben auch per Sprachfunktion mit allen Kontakten oder nur ausgewählten Freunden (geplante Funktion)",
    },
    {
      color: colors.goldenYellow,
      image: iconImage,

      text:
        "Gemeinsame Foto- und Videogalerien der Veranstaltungen sind für jeden zugänglich. (geplante Funktion)",
    },
    {
      color: colors.orchid,
      image: iconMarker,

      text:
        "Nutzer können ihren Live Status und Standort teilen, so dass Freunde spontan vorbeikommen können.(geplante Funktion)",
    },
  ];
  const renderItem = ({ image, text, color }) => {
    return (
      <Col lg={{ span: 4 }}>
        <div
          style={{
            width: "80%",
            flex:1,
            alignItems: "center",
            justifyContent: "center",
          }}
          // className="d-flex"
        >
          <div
            style={{
              width: "5em",
              height: "5em",
              borderRadius: "2.5em",
              backgroundColor: color,
              marginBottom: "1.7em",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              ...styles.shadowVeryLight,
            }}
            className="d-flex mx-auto"
          >
            <img src={image} style={{ width: "2em" }} />
          </div>
          <p
            style={{
              textAlign: "center",
              ...styles.montserratRegular,
              fontSize: "0.8em",
              color: colors.brownishGrey,
            }}
          >
            {text}
          </p>
        </div>
      </Col>
    );
  };

  return (
    <section>
      <Container>
        <Row style={{ marginTop: "10em" }}>
          <Col lg={{ span: 10, offset: 1 }}>
            <Row
              className="justify-content-center"
              style={{ marginBottom: "3.5em" }}
            >
              <span
                style={{
                  alignSelf: "center",
                  ...styles.montserratRegular,
                  color: colors.brownishGrey,
                  fontSize: "2em",
                }}
              >
                Die besten Features im Überblick:
              </span>
            </Row>
            <Row className="justify-content-center">
              {features.map((e) => renderItem(e))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const s = {
  introContainer: {
    borderTopRightRadius: 160,
    overflow: "hidden",
    ...styles.shadowLight,
  },
  introTextBox: {
    display: "flex",
    height: "15em",
    paddingLeft: "3em",
    paddingRight: "3em",
    ...styles.montserratRegular,
    fontSize: "1em",
    color: "white",
  },

  //   Description Cards
  cardImage: { ...styles.shadowLight, width: "100%" },
  cardTextBox: {
    ...styles.bg_purple,
    ...styles.montserratLight,
    fontSize: "0.8em",
    display: "flex",
    height: "9em",
    paddingTop: "1.3em",
    paddingRight: "0.8em",
    paddingLeft: "1.3em",
    color: "white",
    borderBottomLeftRadius: 43,
  },
};
