import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import colors from "../../assets/styles/colors";

// Images
import calendarImg from "../../assets/images/calendar.png";

import iconSprout from "../../assets/icons/icon-sprout.svg";
import iconAgreement from "../../assets/icons/icon-agreement.svg";
import iconChakra from "../../assets/icons/icon-chakra.svg";
import iconHeart from "../../assets/icons/icon-heart.svg";

export default function PhiFeatures() {
  const features = [
    {
      image: iconSprout,
      text: "freie Entfaltung",
    },
    {
      image: iconAgreement,
      text: "Wertschätzung",
    },
    {
      image: iconChakra,
      text: "Gelassenheit ",
    },
    {
      image: iconHeart,
      text: "Herzlichkeit",
    },
  ];
  const renderItem = ({ image, text }) => {
    return (
      <Col lg={{ span: 3 }} className="text-center">
        <div style={{ width: "80%" }}>
          <img
            src={image}
            style={{
              width: "5em",
              height: "5em",
              marginBottom: "1.4em",
            }}
          />
          <p style={{ ...s.text }}>{text}</p>
        </div>
      </Col>
    );
  };

  return (
    <section>
      <Container style={{ marginTop: "6em", marginBottom: "7em" }}>
        <Row className="justify-content-center">
          <p style={{ ...s.title }}>Unsere Werte</p>
          <Col lg={{ span: 10, offset: 1 }}>
            <Row className="justify-content-center">
              {features.map((e) => renderItem(e))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const s = {
  title: {
    ...styles.montserratRegular,
    fontSize: "1.5em",
    color: colors.coralPink,
    marginBottom: "3em",
  },
  text: {
    ...styles.montserratRegular,
    fontSize: "1.1em",
    color: colors.brownishGrey,
  },
};
