import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import styles from "../assets/styles/module.styles";
import bg from "../assets/images/key.jpg";
import TeamJumbotron from "../components/team/TeamJumbotron";
import TeamMembers from "../components/team/TeamMembers";
import TeamContactForm from "../components/team/TeamContactForm";
import Footer from "../components/shared/footer";
import colors from "../assets/styles/colors";
import Jumbotron from "../components/jumpbotron";

const CONTENT = [
  {
    title: "Welche Arten von Daten werden erhoben?",
    text: [
      "Personenbezogene Daten sind alle Informationen über Dich, welche einen Rückschluss auf Deine Person ermöglichen. Ausgeschlossen von dieser Definition sind diejenigen Daten, bei denen die Identität der dahinterstehenden Person unkenntlich gemacht, also anonymisiert wurde. Nähere Informationen erhältst Du im Art. 4 Abs. 1 DSGVO.",
    ],
    table: {
      header: [
        "Kategorie der personen-bezogenen Daten",
        "Beschreibung der Kategorie",
      ],
      data: [
        [
          "Identitätsdaten",
          "Umfassen den Titel, den Vor- und Nachnamen, den Benutzernamen, die E-Mail-Adresse oder ähnliche Identifikatoren sowie eine verschlüsselte Version Deiner Anmeldedaten bzw. Deines Passworts. Wenn Du über soziale Netzwerke mit uns interagierst, ist auch Dein Benutzername in den sozialen Netzwerken mit eingeschlossen.",
        ],
        [
          "Profildaten",
          "Umfassen Deinen Benutzernamen und Dein Passwort, Profilbild, sowie Informationen, welche Du selbst ergänzt. Ebenso gehören Präferenzen, Feedback und Antworten auf Umfragen, sowie Profildaten, die wir hinzugefügt haben (beispielsweise unter Verwendung von Analysen oder Profiling) zu Deinen Profildaten.",
        ],
        [
          "Technische Daten",
          "Umfassen die Internet-Protocol-(IP)-Adresse, Deine Anmeldedaten, Browsertyp und -version, Einstellungen zur Zeitzone und zum Standort, Browser-Plug-in-Typen und -Versionen, Betriebssysteme und Plattformen sowie sonstige Technologien auf den Geräten, die Du für den Zugriff auf unsere Plattformen nutzt.",
        ],
        [
          "Nutzungsdaten",
          "Umfassen Informationen darüber, wie Du unsere Plattform und Dienstleistungen nutzt. Diese Daten nutzen wir unter anderem dazu Dein Nutzungserlebnis zu verbessern.",
        ],
        [
          "Tracking-Daten",
          "Umfassen Informationen, die wir oder andere über Cookies und ähnliche Technologien zur Nachverfolgung (Tracking), wie Web-Beacons, Pixel und mobile Kennungen (Identifier), erfassen.",
        ],
        [
          "Marketing- und Kommunikationsdaten",
          "Umfassen Deine Präferenzen in Bezug auf den Empfang von Direktwerbung von uns sowie Deine Kommunikationspräferenzen. Durch diese Informationen werden wir Dir zum Beispiel nur dann einen Newsletter senden, wenn Du diesen tatsächlich lesen möchtest.",
        ],
      ],
    },
  },
  {
    title: "Newsletter",
    text: [
      "Hier erfährst Du, welche Daten bei der Anmeldung für unseren Newsletter erhoben werden können.",
    ],
    table: {
      header: [
        "Kategorie der personen-bezogenen Daten",
        "Beschreibung der Kategorie",
      ],
      data: [
        [
          "Identitätsdaten",
          "Umfassen den Titel, den Vor- und Nachnamen, den Benutzernamen, die E-Mail-Adresse oder ähnliche Identifikatoren sowie eine verschlüsselte Version Deiner Anmeldedaten bzw. Deines Passworts. Wenn Du über soziale Netzwerke mit uns interagierst, ist auch Dein Benutzername in den sozialen Netzwerken mit eingeschlossen.",
        ],
        [
          "Nutzungsdaten",
          "Umfassen den Titel, den Vor- und Nachnamen, den Benutzernamen, die E-Mail-Adresse oder ähnliche Identifikatoren sowie eine verschlüsselte Version Deiner Anmeldedaten bzw. Deines Passworts. Wenn Du über soziale Netzwerke mit uns interagierst, ist auch Dein Benutzername in den sozialen Netzwerken mit eingeschlossen.",
        ],
        [
          "Tracking-Daten",
          "Umfassen Informationen, die wir oder andere über Cookies und ähnliche Technologien zur Nachverfolgung (Tracking), wie Web-Beacons, Pixel und mobile Kennungen (Identifier), erfassen.",
        ],
        [
          "Marketing- und Kommunikationsdaten",
          "Umfassen Deine Präferenzen in Bezug auf den Empfang von Direktwerbung von uns sowie Deine Kommunikationspräferenzen. Durch diese Informationen werden wir Dir zum Beispiel nur dann einen Newsletter senden, wenn Du diesen tatsächlich lesen möchtest.",
        ],
      ],
    },
    subtext: [
      "Wir sammeln, nutzen und übermitteln aggregierte Daten, wie beispielsweise statistische oder demografische Daten, zum Verbessern Deines Erlebnisses auf unserer Plattform sowie zu anderen Zwecken. Wir fassen zum Beispiel Nutzerdaten zusammen, um Zugriffszahlen auf ein entsprechendes Feature unserer App zu berechnen. Aggregieren bedeutet in diesem Zusammenhang, dass wir Daten so zusammenfassen, dass ein direkter sowie indirekter Rückschluss auf eine dahinterstehende Person nicht mehr möglich ist. Die Daten werden soweit anonymisiert, dass aus den Informationen keine einzelne Person mehr erkennbar ist. Aus diesem Grund werden aggregierte Daten nicht mehr als personenbezogene Daten betrachtet.",
      "Sollten wir aggregierte Daten mit Deinen personenbezogenen Daten kombinieren oder verbinden, sodass ein direkter oder indirekter Rückschluss auf Deine Person möglich wäre, so behandeln wir in dem Fall auch die aggregierten Daten wie personenbezogene Daten, welche ausschließlich im Rahmen unserer Datenschutzerklärung verarbeitet werden.",
      "Bitte beachte, dass wir Dir einige Funktionen unserer App nicht anbieten können, wenn Du Dich dazu entscheiden solltest, uns keine personenbezogenen Daten zur Verfügung zu stellen, oder bestimmte Möglichkeiten zur Kontaktaufnahme verweigerst.",
    ],
  },

  {
    title: "Rechtsgrundlage",
    text: [
      "Nach Vorgabe des Art. 13 DSGVO teilen wir dir die Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern du uns deine Einwilligung zur Verarbeitung deiner personenbezogenen Daten gegeben hast, stellt dies die Rechtsgrundlage der Verarbeitung nach Art. 6 Abs. 1 Buchst. a DSGVO dar. Auf Basis von Art. 6 Abs. 1 Buchst. b DSGVO benötigen wir deine personenbezogenen Daten die zur Erfüllung eines Vertrages erforderlich sind. Dies gilt auch für Verarbeitungsvorgänge, die für vorvertragliche Maßnahmen benötigt werden. Aufgrund rechtlicher Verpflichtungen, wie z.B. die Aufbewahrung von Daten, verarbeiten wir ebenfalls deine Daten, sofern es nach Art. 6 Abs. 1 Buchst. c DSGVO erforderlich sein sollte. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 Buchst. d DSGVO als Rechtsgrundlage.",
      "Außerdem verarbeiten wir deine personenbezogenen Daten zur Wahrnehmung unserer berechtigten Interessen, sowie des berechtigten Interesses Dritter, auf Basis von Art. 6 Abs. 1 Buchst. f DSGVO, um dir z.B. passende Angebote anzeigen zu können. Im Rahmen der jeweils erforderlichen Interessenabwägung berücksichtigen wir deine Grundrechte und Grundfreiheiten die für den Schutz deiner Daten erforderlich sind.",
    ],
  },
  {
    title: "Zu welchen Zwecken verwenden wir Deine Daten?",
    text: [
      "Bei der Nutzung unserer unserer App, verarbeiten wir an diversen Stellen Deine persönlichen Daten. Mit dieser Übersicht möchten wir Dir einen detaillierten und transparenten Einblick über unsere Verarbeitungsprozesse ermöglichen. Wir haben für Dich in der unteren Tabelle detailliert aufgeführt, wann und in welchem Umfang wir welche Kategorie von personenbezogenen Daten verwenden.",
    ],
    table: {
      header: [
        "Verarbeitungszweck",
        "Rechtsgrundlage der Verarbeitung",
        "Kategorien der personenbezogenen Daten",
      ],
      data: [
        [
          {
            title: "Nutzung unserer App",
            text:
              "Wir müssen Deine personenbezogenen Daten verwenden um Dir unsere Dienstleistungen in unserer App zur Verfügung zu stellen. Beispielsweise können wir Dir die Aktivitäten deiner Freunde nur dann anzeigen, wenn ein Account bei uns vorliegt und du mit deiner Telefonnummer eingeloggt bist.",
          },
          ["Vertragserfüllung", "berechtigtes Interesse"],
          [
            "Identitätsdaten",
            "Profildaten",
            "Technische Daten",
            "Nutzungsdaten",
            "Tracking-Daten",
          ],
        ],
        [
          {
            title: "Bereitstellung unseres Kundenservices",
            text:
              "Unser Kundenservice besitzt einen Zugang zu Deinen Identitätsdaten, um sicherzustellen, dass es sich bei Dir auch um den tatsächlichen Besitzer des Accounts handelt.",
          },
          ["Vertragserfüllung", "berechtigtes Interesse"],
          ["Identitätsdaten", "Profildaten"],
        ],
        [
          {
            title: "Verwaltung unserer Beziehung mit Dir",
            text:
              "Sollten wir unsere Geschäftsbedingungen, oder unsere Datenschutzerklärung aktualisieren, würden wir Dich gerne über die Änderungen in Kenntnis setzen. Des Weiteren sind wir sehr an Deiner Meinung interessiert und würden Dich gerne gelegentlich um die Abgabe einer Bewertung bitten.",
          },
          [
            "Vertragserfüllung",
            "Erfüllung einer rechtlichen Verpflichtung",
            "berechtigtes Interesse",
          ],
          [
            "Identitätsdaten",
            "Profildaten",
            "Marketing- und Kommunikationsdaten",
          ],
        ],
        [
          {
            title: "Direktwerbung",
            text:
              "Wir werden Dir niemals Werbung ohne Deine ausdrückliche Einwilligung zukommen lassen. Falls Du Dich entscheidest an unserem Newsletter-Programm teilzunehmen, werden wir Deine Zustimmung als digitale Unterschrift bei uns vermerken. Solltest Du keine Werbung mehr von uns empfangen wollen, kannst Du Deine Einwilligung jederzeit widerrufen.",
          },
          ["Einwilligung", "berechtigtes Interesse"],
          [
            "Identitätsdaten",
            "Profildaten",
            "Technische Daten",
            "Nutzungsdaten",
            "Tracking-Daten",
            "Marketing- und Kommunikationsdaten",
          ],
        ],
        [
          {
            title: "Wartung unserer Plattform",
            text:
              "Wir möchten unsere Plattform stetig verbessern, um Dir ein optimales Nutzungserlebnis zu ermöglichen. Dafür ist eine Analyse des Nutzerverhaltens unumgänglich. Aus den Ergebnissen der Analyse leiten wir Fehlerbeseitigungen, Tests und Systemwartungen ein.",
          },
          ["berechtigtes Interesse"],
          [
            "Identitätsdaten",
            "Technische Daten",
            "Tracking-Daten",
            "Marketing- und Kommunikationsdaten",
          ],
        ],
        [
          {
            title: "Datenanalyse",
            text:
              "Wir analysieren die Nutzung und Auslastung unserer Plattform in Echtzeit und in den meisten Fällen vollständig automatisiert. Die daraus resultierenden Erkenntnisse verwenden wir für die stetige Verbesserung des Nutzungserlebnisses unserer User. Dabei messen wir zum Beispiel den Besucherfluss über unsere Website, sowie die Interaktion mit diversen Unterseiten oder Funktionen.",
          },
          ["berechtigtes Interesse"],
          ["Technische Daten", "Nutzungsdaten", "Tracking-Daten"],
        ],
      ],
    },
  },

  {
    title: "Aufbewahrungsdauer",
    text: [
      "Deine personenbezogenen Daten werden bei uns nur so lange, unter Berücksichtigung modernster Sicherheitsmaßnahmen, aufbewahrt, wie dies für die in dieser Datenschutzerklärung beschriebenen Zwecke angemessen ist. Darüber hinaus berücksichtigen wir gesetzliche Vorschriften, nach denen wir bei bestimmten Daten zu einer gesetzlich geregelten Aufbewahrungszeit verpflichtet sind.",
    ],
  },
  {
    title: "Öffentlich verfügbare Informationen",
    text: [
      "Solltest du losgelöst von der maracuja App auf unserer Seite einen öffentlichen Kommentar hinterlassen, gilt Nachfolgendes: Die folgenden Kategorien personenbezogener Daten werden immer öffentlich auf unserer Plattform für andere Besucher verfügbar sein, so lange bis Du dieser Veröffentlichung widersprochen hast: Eine Verknüpfung zu Deinem Profil, Dein Benutzername, Dein Profilbild und von dir eingestellte Kommentare.",
    ],
  },
  {
    title: "Deine Rechte",
    text: ["Folgende Rechte hast Du bezogen auf Deine persönlichen Daten:"],
    subsections: [
      {
        title: "Widerruf Deiner Einwilligung Art. 7 DSGVO",
        text: [
          "Wenn Du uns Deine Einwilligung zur Verarbeitung personenbezogener Daten gegeben hast, kannst Du diese Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Ein solcher Widerruf hat keinen Einfluss auf die Rechtmäßigkeit der Verarbeitung vor dem Widerruf der Einwilligung. Solltest Du Deine Einwilligung widerrufen, können wir unter Umständen bestimmte Funktionen der Plattform nicht mehr für Dich bereitstellen.",
        ],
      },
      {
        title: "Auskunftsrecht Art. 15 DSGVO",
        text: [
          "Du hast das Recht, von uns eine Bestätigung darüber zu verlangen, ob wir, Dich betreffende personenbezogene Daten verarbeiten. Ist dies der Fall, so besteht zudem ein Recht auf Auskunft über diese personenbezogenen Daten. Es umfasst neben der Auskunft zu den Verarbeitungszwecken, den Kategorien personenbezogener Daten, die verarbeitet werden, auch die Auskunft zu den Empfängern oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden. Bitte beachte, dass es sich bei dem Auskunftsrecht nicht um ein absolutes Recht handelt und berechtigte Interessen anderer Personen zu einer Einschränkung des Auskunftsrechts führen können.",
        ],
      },
      {
        title: "Recht auf Berichtigung Art. 16 DSGVO",
        text: [
          "Du hast das Recht, die Berichtigung Dich betreffender unrichtiger personenbezogener Daten zu verlangen. In vielen Fällen hast Du auch selbst die Möglichkeit, diese Daten direkt in Deinem Benutzerbereich anzupassen. Unter Berücksichtigung der Zwecke der Verarbeitung besteht zudem das Recht, die Vervollständigung unvollständiger personenbezogener Daten zu verlangen, dies mittels einer ergänzenden Erklärung.",
        ],
      },
      {
        title: "Recht auf Löschung („Recht auf Vergessenwerden“) Art. 17 DSGVO",
        text: [
          "Bei Vorliegen der entsprechenden Voraussetzungen kannst Du verlangen, dass die Dich betreffenden personenbezogenen Daten unverzüglich gelöscht werden. Für den Fall, dass eine gesetzliche Aufbewahrungspflicht besteht oder die Daten aufbewahrt werden müssen, um eine uns zustehende Forderung bzw. ein uns zustehendes Recht geltend machen zu können, behalten wir uns vor, die Daten weiter aufzubewahren.",
        ],
      },
      {
        title: "Recht auf Einschränkung der Verarbeitung Art. 18 DSGVO",
        text: [
          "Bei Vorliegen der entsprechenden Voraussetzungen kannst Du die Einschränkung der Verarbeitung verlangen. In diesem Fall werden die betreffenden personenbezogenen Daten entsprechend gekennzeichnet und gegebenenfalls nur noch zu bestimmten Zwecken verarbeitet.",
        ],
      },
      {
        title: "Recht auf Datenübertragbarkeit Art. 20 DSGVO",
        text: [
          "Bei Vorliegen der entsprechenden Voraussetzungen steht Dir ein Recht auf Datenübertragbarkeit hinsichtlich der Dich betreffenden personenbezogenen Daten zu. Dieses Recht erstreckt sich auf die personenbezogenen Daten, die Du uns bereitgestellt hast. Du hast das Recht, diese Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und ggf. diese Daten einem anderen Verantwortlichen, dem die personenbezogenen Daten bereitgestellt werden sollen, zu übermitteln.",
        ],
      },
    ],
  },
  {
    title: "Weitergabe personenbezogener Daten an Dritte",
    text: [
      "Wir werden Deine personenbezogenen Daten nicht an Dritte Parteien verkaufen, verleihen oder öffentlich zugänglich machen. Wir geben Deine Daten lediglich zur vertraglichen Erfüllung an Partner weiter, damit wir Dir unsere Dienste anbieten können.",
      "In unserem Kundenservice und Marketing nutzen wir diverse Tools, um mit Dir im Kontakt zu bleiben. Dazu gehören Online-Dienste, mit denen wir gezielt Newsletter-Kampagnen planen und erstellen können. All diese Online-Dienste verfügen über hohe Sicherheitssysteme und umfangreiche Datenschutzerklärungen, denen wir als Unternehmen vertrauen. Im Rahmen der Nutzung dieser Online-Dienste findet ein Austausch personenbezogener Daten statt. Über all diese Daten behalten wir die Datenhoheit, so dass eine Löschung zu jeder Zeit möglich ist. Des Weiteren besteht mit unseren Partnern eine gezielte Vereinbarung, über die Art der Datenverarbeitung.",
      "Einige unserer Partner und Online-Dienste haben Ihren Sitz außerhalb der EU. Bei ihrer Auswahl haben wir besonders darauf geachtet, dass sie verantwortlich mit personenbezogenen Daten umgehen und mit jedem von ihnen die die Verarbeitung individuell vereinbart. Dabei gewährleisten unsere Partner jederzeit einen ordnungsgemäßen Umgang Deiner personenbezogenen Daten.",
      "Unter besonderen Umständen können wir Deine personenbezogenen Daten an eine dritte Partei weiterleiten, wenn es uns entweder gesetzliche Bestimmungen gestatten oder wir gesetzlich dazu verpflichtet sind.",
    ],
  },
  {
    title: "Newsletter",
    text: [
      "Mit unserem Newsletter wollen wir dich regelmäßig über Neuigkeiten zu maracuja und unseren Leistungen informieren, Informationen zu kooperierenden Partnern geben, Berichte über Events und Veranstaltungen sowie von uns verfasste Artikel mit dir teilen.",
      "Wenn du unseren Newsletter abonnieren möchtest, benötigen wir deine e-mail Adresse, an der wir diesen schicken können. Wir senden dir den Newsletter nur mit deiner ausdrücklichen Einwilligung. Dies funktioniert folgendermaßen: ",
      "Nach Eingabe deiner E-Mail und einem Klick auf “Absenden” erhältst du eine Bestätigungs-E-Mail an die angegebene E-Mail-Adresse. Den Newsletter versenden wir nur nach deiner ausdrücklichen Bestätigung durch Klick auf einen in der Bestätigungs-E-Mail hinterlegten Link (sog. Double-Opt-In).",
      "Wir versenden den Newsletters und die mit ihm verbundene Erfolgsmessung auf Basis deiner Einwilligung gem. Art. 6 Abs. 1 Buchst. a, Art. 7 DSGVO. Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO. Unser Interesse basiert auf dem Einsatz eines für dich benutzerfreundlichen sowie sicheren Newslettersystems, das sowohl unseren geschäftlichen Interessen dient, als auch deinen Erwartungen entspricht und uns ferner den Nachweis von Einwilligungen erlaubt.",
    ],
    subsections: [
      {
        title: "Widerrufsmöglichkeit:",
        text: [
          "Selbstverständlich hast du das Recht, jederzeit die von dir erteilte Einwilligung für die Zukunft zu widerrufen und dich vom Newsletter abzumelden. Hierzu brauchst du nur auf die Schaltfläche im Newsletter zu klicken. Deine E-Mail-Adresse wird nach erfolgtem Widerruf gelöscht.",
        ],
      },
      {
        title: "Nutzung von mailchimp:",
        text: [
          "Für den Versand der Newsletter verwenden wir den Versanddienstleisters „MailChimp“, eine Newsletterversandplattform des US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. Die Datenschutzbestimmungen des Versanddienstleisters kannst du hier einsehen: mailchimp legal . The Rocket Science Group LLC d/b/a MailChimp ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäisches Datenschutzniveau einzuhalten (privacyshield). Der Versanddienstleister wird auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 Buchst. f DSGVO und eines Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S.1 DSGVO eingesetzt.",
          "Mailchimp kann die email-Daten von dir in pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer, zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen Optimierung des Versandes und der Darstellung der Newsletter oder für statistische Zwecke. Mailchimp nutzt die Daten von dir jedoch nicht, um dich anzuschreiben oder deine Daten an Dritte weiterzugeben.",
        ],
      },
    ],
  },
  {
    title: "Google Fonts",
    text: [
      "Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt dein Browser die benötigten Web Fonts in deinen Browsercache, um Texte und Schriftarten korrekt anzuzeigen.",
      'Zu diesem Zweck binden wir die Schriftarten ("Google Fonts") des Anbieters Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein. Nach Angaben von Google werden die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im Browser der Nutzer verwendet. Die Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer technisch sicheren, wartungsfreien und effizienten Nutzung von Schriftarten, deren einheitlicher Darstellung sowie Berücksichtigung möglicher lizenzrechtlicher Restriktionen für deren Einbindung.',
      "Weitere Informationen zu Google Web Fonts findest du unter: Google-Dev und in der Datenschutzerklärung von Google: Google-Datenschutz.",
      "Wenn dein Browser Web Fonts nicht unterstützt, wird eine Standardschrift von deinem Computer genutzt.",
    ],
  },
  {
    title: "Links",
    text: [
      "Wir können Werbung von Dritten und andere Inhalte einblenden, die Links auf Webseiten Dritter enthalten. Wir haben keinen Einfluss auf die Datenschutzpraktiken und Inhalte Dritter, und können auch keine Verantwortung hierfür übernehmen. Wenn Du auf eine Werbung oder einen Link Dritter klickst, sei Dir bitte bewusst, dass Du unsere Plattform verlässt und dass jegliche personenbezogenen Daten, die Du zur Verfügung stellst, nicht mehr Teil diese Datenschutzerklärung sein werden. Bitte lese Dir die Datenschutzerklärungen Dritter sorgfältig durch, um herausfinden, wie sie Deine personenbezogenen Daten erheben und verarbeiten.",
    ],
  },
  {
    title: "Cookies und Reichweitenmessung",
    text: [
      "Cookies sind Informationen, die von unserem Webserver oder Webservern Dritter auf Deinen Web-Browser übertragen und dort für einen späteren Abruf gespeichert werden. Bei Cookies kann es sich um kleine Dateien oder sonstige Arten der Informationsspeicherung handeln.",
    ],
    subsections: [
      {
        title: "Session-Cookies",
        text: [
          "Wir verwenden „Session-Cookies“, die nur für die Zeitdauer des aktuellen Besuchs auf unserer Onlinepräsenz abgelegt werden (z.B. um die Speicherung Deines Login-Status überhaupt ermöglichen zu können). In einem Session-Cookie wird eine zufällig erzeugte eindeutige Identifikationsnummer abgelegt, eine sogenannte Session-ID. Außerdem enthält ein Cookie die Angabe über seine Herkunft und die Speicherfrist. Diese Cookies können keine anderen Daten speichern. Session-Cookies werden gelöscht, sobald Du die Nutzung unseres Onlineangebotes beendet hast und Dich z.B. ausloggst oder den Browser schließt.",
        ],
      },
      {
        title: "Statistik-Cookies",
        text: [
          "Wir verwenden auf unserer Plattform Google Analytics, einen Webanalysedienst der Google LLC („Google“), um die Anzahl der Besucher auf unserer Seite und ihr Verhalten auf unserer Seite nachzuvollziehen und zu messen. Google verwendet Cookies. Die durch das Cookie erzeugten Informationen über die Benutzung unserer Plattform werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.",

          "Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (https://www.privacyshield.gov).",

          "Google Analytics verwendet diese Informationen, um für uns Auswertungen über das Verhalten unserer Nutzer und Zielgruppen bereitzustellen, mit denen wir unsere Funktionen in der Zukunft verbessern können. Bei der Verarbeitung dieser Daten, werden von Google pseudonyme Nutzerprofile von Dir erstellt.",

          "Darüber hinaus nutzen wir Google Analytics, um die, durch Werbedienste Googles und seiner Partner geschalteten Anzeigen, Dir nur dann anzuzeigen, wenn bei Dir auch Interesse an dieser Werbeanzeige besteht oder Du bestimmte Merkmale (z.B. Interesse an bestimmten Themen oder Reisen) aufweist, die wir an Google übermitteln (zum sogenannten „Remarketing“ bzw. „Google-Analytics-Audiences“). Mit Hilfe dieser Maßnahme möchten wir sicherstellen, dass unsere Anzeigen Dich nicht belästigen, sondern Dich nur dann erreichen, wenn sie für Dich relevant sind.",

          "Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, Deine IP-Adresse wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt übermittelt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.",

          "Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten findest Du auf den Webseiten von Google: https://www.google.com/intl/de/policies/privacy/partners („Datennutzung durch Google bei Ihrer Nutzung von Websites oder Apps unserer Partner“), https://policies.google.com/technologies/ads („Datennutzung zu Werbezwecken“), https://adssettings.google.com/authenticated („Informationen verwalten, die Google verwendet, um Ihnen Werbung einzublenden“).",
        ],
      },
      {
        title: "Werbungs-Cookies",
        text: [
          "Wir nutzen Cookies auf unserer Seite um dir Werbung basierend auf Deinen Suchergebnissen anzuzeigen. In keinem Fall wird Dir durch dieses Vorgehen personalisierte Werbung außerhalb unserer Plattform angezeigt.",
        ],
      },

      {
        title: "Cookies von sozialen Netzwerken",
        text: [
          "Wenn Du soziale Netzwerke wie Facebook oder Instagram verwendest, dann speichern diese Cookies in Deinem Browser, welcher auch bei dem Besuch unserer Webseite verfügbar ist. Wir möchten Dich daher an dieser Stelle darauf hinweisen, dass unsere Datenschutzerklärung den Umfang dieser Cookies nicht widerspiegelt und verweisen daher auf die Datenschutzerklärung des jeweiligen Netzwerkes:  ",
        ],
        items: [
          " Facebook: https://www.facebook.com",
          " Instagram: https://instagram.com",
          " Pinterest: https://www.pinterest.com",
          " Twitter: https://twitter.com}",
        ],
      },

      {
        title: "Cookies löschen",
        text: [
          "Falls Du nicht möchtest, dass Cookies auf Deinem Rechner gespeichert werden, dann wirst Du gebeten die entsprechende Option in den Systemeinstellungen Deines Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen des Browsers gelöscht werden. Bitte beachte, dass der Ausschluss von Cookies zu Funktionseinschränkungen unseres Onlineangebotes führen kann.",
        ],
      },
      {
        title: "Facebook-, Custom Audiences und Facebook-Marketing-Dienste",
        text: [
          'Innerhalb unseres Onlineangebotes wird aufgrund unserer berechtigten Interessen an Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes und zu diesen Zwecken das sog. "Facebook-Pixel" des sozialen Netzwerkes Facebook, welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook"), eingesetzt.',
          "Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active).",
          'Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, dich als Besucher unseres Onlineangebotes als Zielgruppe für die Darstellung von Anzeigen (sog. "Facebook-Ads") zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Facebook übermitteln (sog. „Custom Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere Facebook-Ads deinem potentiellen Interesse entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, in dem wir sehen ob du nach einem Klick auf eine Facebook-Werbeanzeige auf unsere Website weitergeleitet wurdest (sog. „Conversion“).',
          "Des Weiteren nutzen wir beim Einsatz des Facebook-Pixels die Zusatzfunktion „erweiterter Abgleich“ (hierbei werden Daten wie Telefonnummern, E-Mailadressen oder Facebook-IDs der Nutzer) zur Bildung von Zielgruppen („Custom Audiences“ oder „Look Alike Audiences“) an Facebook (verschlüsselt) übermittelt. Weitere Hinweise zum „erweiterten Abgleich“: https://www.facebook.com/business/help/611774685654668).",
          "Wir nutzen ebenfalls das Verfahren „Custom Audiences from File“ des sozialen Netzwerks Facebook, Inc. In diesem Fall werden die E-Mail-Adressen der Newsletterempfänger bei Facebook hochgeladen. Der Upload-Vorgang findet verschlüsselt statt. Der Upload dient alleine, um Empfänger unserer Facebook-Anzeigen zu bestimmen. Wir möchten damit sicherstellen, dass die Anzeigen nur Nutzern angezeigt werden, die ein Interesse an unseren Informationen und Leistungen haben.",
          "Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen von Facebooks Datenverwendungsrichtlinie. Dementsprechend generelle Hinweise zur Darstellung von Facebook-Ads, in der Datenverwendungsrichtlinie von Facebook: https://www.facebook.com/policy.php. Spezielle Informationen und Details zum Facebook-Pixel und seiner Funktionsweise erhälst du im Hilfebereich von Facebook: https://www.facebook.com/business/help/651294705016616.",
          "Du kannst der Erfassung durch den Facebook-Pixel und Verwendung deiner Daten zur Darstellung von Facebook-Ads widersprechen. Um einzustellen, welche Arten von Werbeanzeigen dir innerhalb von Facebook angezeigt wird, kannst du die von Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den Einstellungen nutzungsbasierter Werbung befolgen: https://www.facebook.com/settings?tab=ads. Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.",
          "Du kannst dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen, ferner über die Deaktivierungsseite der Netzwerkwerbeinitiative (http://optout.networkadvertising.org/) und zusätzlich die US-amerikanische Webseite (http://www.aboutads.info/choices) oder die europäische Webseite (http://www.youronlinechoices.com/uk/your-ad-choices/) widersprechen.",
        ],
      },
    ],
  },
  {
    title: "Anpassung der Datenschutzerklärung",
    text: [
      "Die Datenschutz-Grundverordnung ist neu und die Europäische Union gibt noch immer neue Anleitungsdokumente heraus, wie die Unternehmen sie befolgen sollten. Deshalb könntest Du im Laufe der kommenden Monate möglicherweise kleinere Aktualisierungen unserer Datenschutzrichtlinie bemerken. Stelle bitte sicher, dass Du sie gelegentlich erneut liest und prüfst. Vielen Dank.",
    ],
  },
  ,
];

const Content = () => {
  return (
    <section>
      <Row className="justify-content-center">
        <Col lg={{ span: 10 }}>
          <p style={s.title}>Einführung</p>
          <p style={s.text}>
            Vielen Dank für Dein Vertrauen in maracuja.
            <br />
            Datenschutz ist uns sehr wichtig, daher informieren wir Dich auf der
            folgenden Seite über die Erhebungs- und Verarbeitungsprozesse von
            Daten in unserem Unternehmen. Wir sind der festen Überzeugung, dass
            Datenschutz ein grundlegendes Recht unser Nutzer und eines jeden
            Menschen ist. Aus diesem Grund minimieren wir die Erhebung von Daten
            und sorgen mit größtmöglicher Sorgfalt für ihren Schutz. Da es sich
            bei Datenschutzerklärungen in den meisten Fällen um undurchsichtige
            und fachsprachliche Dokumente handelt, möchten wir Dir in einfachen
            Worten nahebringen, wozu wir Informationen von Dir benötigen, wo wir
            diese speichern und welche Rechte Du in Bezug auf deine eigenen
            Daten ausüben kannst. Wir von maracuja wollen mit deinen Daten dafür
            sorgen, dass du dich mit deinen Freunden einfacher verabreden kannst
            und mehr Zeit mit ihnen im realen Leben verbringen kannst.
            <br />
          </p>
          <p style={s.title}>Wir schützen Deine Privatsphäre</p>
          <p style={s.text}>
            Datenschutz hat bei uns oberste Priorität! Wir haben alle
            technischen und organisatorischen Maßnahmen getroffen um Deine Daten
            im höchsten Maße zu schützen, sowie die strengen Normen der
            Datenschutzgrundverordnung im vollen Umfang zu erfüllen. Des
            Weiteren verschlüsseln wir sensible Daten, wie beispielsweise
            Passwörter, mit den modernsten und sichersten
            Verschlüsselungsverfahren, sodass diese auch im Falle eines
            Datenverlustes weiterhin unkenntlich bleiben.
          </p>

          <p style={s.title}>
            Verantwortlich im Sinne der Datenschutz-Grundverordnung (DSGVO)
            sind:
          </p>
          <p style={s.text}>
            mara cuja UG (haftungsbeschränkt) <br />
            Grüner Weg 119
            <br />
            58644 Iserlohn
            <br />
            Vertreten durch:
            <br />
            Vadim Eberle
            <br />
            E-Mail: hello@maracuja.app
            <br />
            HRB Nr.: 9405
            <br />
          </p>
          <p style={s.title}>Welche Arten von Daten werden erhoben?</p>
          <p style={s.text}>
            Personenbezogene Daten sind alle Informationen über Dich, welche
            einen Rückschluss auf Deine Person ermöglichen. Ausgeschlossen von
            dieser Definition sind diejenigen Daten, bei denen die Identität der
            dahinterstehenden Person unkenntlich gemacht, also anonymisiert
            wurde. Nähere Informationen erhältst Du im Art. 4 Abs. 1 DSGVO.
          </p>
        </Col>
      </Row>
    </section>
  );
};

const Table1 = ({ data: { header, data } }) => {
  return (
    <section className="px-3">
      <Row
        style={{
          backgroundColor: colors.coralPink,
          borderRadius: 15.5,
          fontSize: "0.9em",
          marginBottom: "0.7em",
          ...s.text,
          color: "white",
          ...styles.montserratSemiBold,
        }}
        className="px-3 py-4 align-items-center"
      >
        {header.map((c, index) => {
          return index === 0 ? (
            <Col lg={{ span: 4 }}>
              <span>{c}</span>
            </Col>
          ) : (
            <Col lg={{ offset: 1 }}>
              <span>{c}</span>
            </Col>
          );
        })}
      </Row>
      {data.map((c, index) => {
        return (
          <Row
            style={{
              // backgroundColor: colors.coralPink,
              borderRadius: 15.5,
              marginBottom: "0.7em",
              borderWidth: 3.3,
              borderColor: colors.coralPink,
              borderStyle: "solid",
              ...s.text,
            }}
            className="px-3 py-3 align-items-center "
          >
            <Col lg={{ span: 4 }}>
              <span
                style={{
                  ...styles.montserratSemiBold,
                  color: colors.coralPink,
                }}
              >
                {c[0]}
              </span>
            </Col>
            <Col lg={{ offset: 1 }}>
              <span
                style={{
                  ...styles.montserratRegular,
                  color: colors.brownishGrey,
                }}
              >
                {c[1]}
              </span>
            </Col>
          </Row>
        );
      })}
    </section>
  );
};

const Table2 = ({ data: { header, data } }) => {
  return (
    <section className="px-3">
      <Row
        style={{
          backgroundColor: colors.coralPink,
          borderRadius: 15.5,

          marginBottom: "1em",

          ...s.text,
          color: "white",
          ...styles.montserratSemiBold,
        }}
        className="px-3 py-4 align-items-center"
      >
        {header.map((c, index) => {
          return index === 0 ? (
            <Col lg={{ span: 5 }}>
              <span>{c}</span>
            </Col>
          ) : (
            <Col>
              <span>{c}</span>
            </Col>
          );
        })}
      </Row>
      {data.map((d) => {
        return (
          <Row
            style={{
              borderRadius: 15.5,
              marginBottom: "0.7em",
              borderWidth: 3.3,
              borderColor: colors.coralPink,
              borderStyle: "solid",
              ...s.text,
            }}
            // className="align-items-center"
            className="px-3 py-3 align-items-center  "
          >
            <Col lg={{ span: 5 }}>
              <span
                style={{
                  ...styles.montserratSemiBold,
                  fontSize: "0.9em",
                  color: colors.coralPink,
                }}
              >
                {d[0].title}
              </span>
              <br />
              <span>{d[0].text}</span>
            </Col>
            <Col>
              <ul style={{ listStyleType: "none" }}>
                {d[1].map((v) => (
                  <li>- {v}</li>
                ))}
              </ul>
            </Col>
            <Col>
              <ul style={{ listStyleType: "none" }}>
                {d[2].map((v) => (
                  <li>- {v}</li>
                ))}
              </ul>
            </Col>
          </Row>
        );
      })}
    </section>
  );
};
const Cat = ({ title, description }) => {
  return (
    <Row
      style={{
        backgroundColor: colors.coralPink,
        borderRadius: 15.5,
        ...styles.montserratSemiBold,
        fontSize: "0.9em",
        color: "white",
        marginBottom: "1em",
      }}
      className="px-5 py-4 align-items-center"
    >
      <Col lg={{ span: 4 }}>
        <span>{title}</span>
      </Col>
      <Col lg={{ offset: 1 }}>
        <span>{description}</span>
      </Col>
    </Row>
  );
};

const Legals = () => {
  return (
    <Row className="justify-content-center px-0">
      <Col lg={{ span: 10 }}>
        {/* <Header
          title="Kategorie der personen-bezogenen Daten"
          description="Beschreibung der Kategorie"
        /> */}
        {CONTENT.map((c) => {
          return (
            <div>
              <p style={s.title}>{c.title}</p>
              {c.text &&
                c.text.length > 0 &&
                c.text.map((t) => (
                  <p style={s.text} className="px-0">
                    {t}
                  </p>
                ))}
              {c.table &&
                (c.table.header.length === 2 ? (
                  <Table1 data={c.table} />
                ) : (
                  <Table2 data={c.table} />
                ))}
              {c.subtext &&
                c.subtext.map((t) => (
                  <p style={s.text} className="px-0">
                    {t}
                  </p>
                ))}
              {c.subsections &&
                c.subsections.map((c) => {
                  return (
                    <div>
                      <p style={{ ...s.title, ...styles.montserratLight }}>
                        {c.title}
                      </p>
                      {c.text.map((t) => {
                        return <p style={s.text}>{t}</p>;
                      })}

                      {c.items && (
                        <ul>
                          {c.items.map((e) => (
                            <li style={s.text}>{e}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

export default function DatenschutzView() {
  return (
    <div style={{ flex: 1 }}>
      <Jumbotron image={bg} height="80vh" className="align-items-end">
        <Container>
          <Row className="justify-content-left">
            <Col className="offset-1" >
              <span
                style={{
                  ...styles.montserratLight,
                  fontSize: "1.4em",
                  color: "#202020",
                }}
              >
                Datenschutzerklärung
              </span>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      {/* Membmers */}

      <Container style={{ marginBottom: "7em" }}>
        <Content />

        <Legals />
        <Row className="justify-content-center">
          <Col lg={{ span: 10 }}>
            <p style={s.title}>Kontakt</p>
            <p style={s.text}>
              Danke, dass Du unsere Datenschutzerklärung gelesen hast. Solltest
              du noch weitere Fragen hierzu haben, kannst Du uns gerne über die
              u. a. Adresse kontaktieren.
            </p>
            <p style={s.text}>
              mara cuja UG (haftungsbeschränkt)
              <br /> Grüner Weg 119
              <br /> 58644 Iserlohn
            </p>
            <a
              href="mailto:hello@maracuja.app"
              style={{ ...s.title, ...styles.montserratLight }}
            >
              hello@maracuja.app
            </a>
          </Col>
        </Row>
      </Container>
      {/* Footer */}
      <Footer color={colors.coralPink} />
    </div>
  );
}

const s = {
  title: {
    ...styles.montserratSemiBold,
    fontSize: "0.9em",
    color: colors.coralPink,
  },
  text: {
    ...styles.montserratRegular,
    fontSize: "0.7em",
    color: colors.brownishGrey,
  },
};
