import colors from "./colors";

export default {
  navLinkText: {
    fontFamily: "Montserrat",
    fontSize: 14,
    lineHeight: 18,
  },

  jumbotronTitle: {
    fontFamily: "Montserrat-Bold",
    fontSize: "2em",
    // lineHeight: 18,
    color: "white",
  },
  jumbotronText: {
    fontFamily: "Montserrat-Light",
    fontSize: "1.5em",
    // lineHeight: 15,
    color: "white",
  },
  bg_yellow: {
    backgroundColor: colors.macaroniCheese,
  },
  bg_red: {
    backgroundColor: colors.coralPink,
  },
  bg_purple: {
    backgroundColor: colors.orchid,
  },
  bg_dark: {
    backgroundColor: colors.brownishGrey,
  },
  montserratThin: {
    fontFamily: "Montserrat",
    fontWeight: 300,
  },
  montserratRegular: {
    fontFamily: "Montserrat",
    // fontWeight: 'regular',
  },
  montserratLight: {
    fontFamily: "Montserrat",
    // fontWeight: "regular",
    fontWeight: 400,
  },
  montserratMedium: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  montserratSemiBold: {
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  montserratBold: {
    fontFamily: "Montserrat",
    // fontStyle: "bold",
    fontWeight: 700,
  },

  shadowVeryLight: {
    boxShadow: "0px 4px 15px 0px rgba(0,0,0,0.16)",
  },
  shadowLight: {
    boxShadow: "0px 5px 7px 0px rgba(0,0,0,0.22)",
  },
  shadowMedium: {
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.16)",
  },
  shadow: (x, y, b, s, o) => ({
    boxShadow: `${x}px ${y}px ${b}px ${s}px rgba(0,0,0,0,${o})`,
  }),

  roundedBottomRightCorner: {
    borderBottomRightRadius: 16,
  },
};
