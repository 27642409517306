import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";

import social1 from "../../assets/images/social-intro-3.png";

export default function SocialContentBanner() {
  return (
    <section>
      <Container
        fluid
        style={{
          borderTopRightRadius: 160,
          borderBottomLeftRadius: 160,
          overflow: "hidden",
          ...styles.shadowVeryLight,
        }}
      >
        <Row
          style={{
            backgroundImage: `url(${social1})`,
            backgroundPositionY: "top",
            backgroundSize: "cover",
            height: "80vh",
            width: "100vw",
          }}
        >
          <Col
            // lg={{ span: 6 }}
            style={{
              paddingLeft: "3em",
              paddingTop: "3em",
            }}
            // className="align-self-center"
          >
            <span
              style={{
                ...styles.montserratRegular,
                fontSize: "1.4em",
                color: "white",
              }}
            >
              Warum physischer Kontakt zu
              <br /> Freunden unverzichtbar ist!
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
