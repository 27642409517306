import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/header";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import HomeView from "./Views/HomeView";
import TeamView from "./Views/TeamView";
import BeSocialView from "./Views/BeSocialView";

import PhiView from "./Views/PhiView";
import DatenschutzView from "./Views/DatenschutzView";
import ImpressumView from "./Views/ImpressumView";
import AGBView from "./Views/AGBView";
import FreshView from "./Views/FreshView";
function App() {
  return (
    <Router>
      <Header />

      <Switch>
        <Route exact path="/">
          <HomeView />
        </Route>
        <Route exact path="/social">
          <BeSocialView />
        </Route>
        <Route exact path="/team">
          <TeamView />
        </Route>
        <Route exact path="/philosophy">
          <PhiView />
        </Route>
        <Route exact path="/datenschutzbestimmungen">
          <DatenschutzView />
        </Route>
        <Route exact path="/impressum">
          <ImpressumView />
        </Route>
        <Route exact path="/agb">
          <AGBView />
        </Route>
        <Route exact path="/fresh">
          <FreshView />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
