import React from "react";

import { Button } from "react-bootstrap";
import colors from "../../assets/styles/colors";
export default function ActionButton({ title = "Abschicken!" }) {
  return (
    <Button
      type="submit"
      style={{
        backgroundColor: colors.coralPink,
        borderColor: colors.coralPink,
        paddingLeft: "2em",
        paddingRight: "2em",
      }}
    >
      {title}
    </Button>
  );
}
