import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import colors from "../../assets/styles/colors";

// Images
import bg from "../../assets/images/start-1.jpg";
import img from "../../assets/images/app-1.jpg";
import iosButton from "../../assets/images/kindpng_2552712.png";
import androidButton from "../../assets/images/kindpng_255271.png";

export default function AppBenefit() {
  return (
    <section>
      <Container fluid>
        <Row
          style={{
            marginTop: "15em",
            backgroundImage: `url(${bg})`,
            height: "80vh",
            backgroundSize: "cover",
            borderBottomLeftRadius: 160,
          }}
          className="justify-content-center"
        >
          <Col lg={{ span: 8 }} sm={{ span: 8 }} className="text-center">
            <p
              style={{
                ...styles.montserratRegular,
                color: colors.brownishGrey,
                paddingTop: "2em",
                fontSize: "1.1em",
              }}
            >
              Menschen verbringen ihre Zeit immer häufiger am Smartphone,
              kommunizieren darüber und bauen sich zeitgleich eine virtuelle
              Welt auf - das echte soziale Leben bleibt auf der Strecke!
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row
          className="justify-content-center align-items-center"
          style={{ paddingTop: "5em", paddingBottom: "9em" }}
        >
          <Col
            lg={{ span: 5, offset: 1 }}
            // sm={{ span: 10, order: 2 }}
            style={s.text}
          >
            <p style={s.title}>
              Wir sind der Überzeugung, dass ohne reale Beziehungen ein
              Menschenleben unerfüllt bleibt.
            </p>
            <br />
            <p>
              Likes und “Gefällt mir”-Kommentare bekommen wir gerne. Aber wir
              sehnen uns auch danach, unsere Freunde mal wieder zu treffen und
              gemeinsam etwas zu unternehmen.
            </p>
            <p>
              maracuja hilft, das reale Leben mit Freunden wieder zu entdecken
              und Freundschaften zu vertiefen.
            </p>
            <p>
              Unsere Social Life App nutzt die beste digitale Technologie, um es
              Dir einfach und unterhaltsam zu machen, Dein nächstes Treffen mit
              Deinen Freunden und Bekannten offline zu planen.
            </p>

            <div style={{ marginTop: "5em" }} className="text-sm-center text-lg-left">
              <img
                src={iosButton}
                height={"43em"}
                style={{ marginRight: "4em" }}
              />
              <img src={androidButton} height={"43em"} />
            </div>
          </Col>
          <Col className="text-center">
            <img src={img} style={{ width: "80%" }} />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const s = {
  title: {
    ...styles.montserratRegular,
    fontSize: "1.3em",
    color: colors.brownishGrey,
  },
  text: {
    ...styles.montserratRegular,
    fontSize: "0.91em",
    color: colors.brownishGrey,
  },
};
