import React from "react";

import {
  Row,
  Col,
  Container,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import iosButton from "../../assets/images/kindpng_2552712.png";
import androidButton from "../../assets/images/kindpng_255271.png";
import logo from "../../assets/images/maracuja logo top.png";
import Jumbotron from "../jumpbotron";
import colors from "../../assets/styles/colors";

import vadimImg from "../../assets/images/team-vadim.jpg";
import valeriaImg from "../../assets/images/team-veleria.jpg";
import bg from "../../assets/images/team-1.jpg";
import ActionButton from "../shared/ActionButton";

export default function TeamContactForm() {
  return (
    <section>
      <Container fluid>
        <Row
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPositionY: "center",
            backgroundSize: "cover",
            width: "100vw",
            // backgroundColor: "blue",
            paddingTop: "4em",
            paddingBottom: "4em",
          }}
          className="justify-content-center"
        >
          <Col lg={{ span: 6 }}>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label style={s.label}>Name:</Form.Label>
                <Form.Control
                  type="text"
                  style={{ ...styles.roundedBottomRightCorner }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label style={s.label}>E-Mail:</Form.Label>
                <Form.Control
                  type="email"
                  style={{ ...styles.roundedBottomRightCorner }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label style={s.label}>Deine Nachricht:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="20"
                  style={{ ...styles.roundedBottomRightCorner, resize: "none" }}
                />
              </Form.Group>
              <ActionButton />
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const s = {
  label: {
    ...styles.montserratRegular,
    color: "white",
    fontSize: "0.8em",
  },
};
