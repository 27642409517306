import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import iosButton from "../../assets/images/kindpng_2552712.png";
import androidButton from "../../assets/images/kindpng_255271.png";
import logo from "../../assets/images/maracuja logo top.png";
import bg from "../../assets/images/start.jpg";
import Jumbotron from "../jumpbotron";
import colors from "../../assets/styles/colors";

export default function PhiContent() {
  return (
    <section>
      <Container fluid>
        <Row className="justify-content-center">
          <Col
            lg={{ span: 7 }}
            style={{ ...s.text, marginTop: "2em", marginBottom: "8em" }}
            className="text-center"
          >
            <p style={{ ...s.title, marginTop: "2em" }}>
              maracuja verbindet Freunde und Mitmenschen in der realen Welt
              miteinander.
            </p>
            <p>
              Wir sind das Gegenteil von oberflächlich. Tiefe Verbundenheit mit
              unseren Freunden und Mitmenschen durch gemeinsame Erlebnisse in
              der realen Welt ist unser Leitbild.
            </p>

            <p>Oberflächliches Daumenhoch war gestern.</p>

            <p style={{ ...s.title, marginTop: "3em" }}>
              Die App, die sich um Freundschaften kümmert{" "}
            </p>

            <p>
              Unsere Mission ist es, echte Beziehungen außerhalb der digitalen
              Welt zu fördern. Das, was Facebook für die digitalen
              zwischenmenschlichen Verbindungen erschaffen hat, realisiert
              maracuja für das reale Leben.
            </p>
            <p style={{ ...s.title, marginTop: "3em" }}>
              Unsere Werte - freie Entfaltung, Wertschätzung, Gelassenheit und
              Herzlichkeit - leiten und begleiten uns in unserem Projekt. Unser
              Fokus richtet sich auf die Gemeinsamkeit in freundschaftlichen
              Beziehungen.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const s = {
  title: {
    ...styles.montserratRegular,
    fontSize: "1.2em",
    color: colors.coralPink,
  },
  text: {
    ...styles.montserratRegular,
    fontSize: "0.9em",
    color: colors.brownishGrey,
  },
};
