import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import iosButton from "../../assets/images/kindpng_2552712.png";
import androidButton from "../../assets/images/kindpng_255271.png";
import logo from "../../assets/images/maracuja logo top.png";
import bg from "../../assets/images/start.jpg";
import Jumbotron from "../jumpbotron";
import colors from "../../assets/styles/colors";

import img1 from "../../assets/images/phi-1.jpg";
import img2 from "../../assets/images/phi-2.jpg";
import img3 from "../../assets/images/phi-3.png";

export default function PhiBigBanner() {
  return (
    <section>
      <Container
        fluid
        style={
          {
            // ...styles.shadowVeryLight,
          }
        }
      >
        <Row
          style={{
            backgroundImage: `url(${img3})`,
            backgroundPositionY: "center",
            backgroundPositionX: "30%",
            backgroundSize: "cover",
            width: "100vw",
            // ...styles.shadow(0, 10, 43.5, 0, 0.35),
          }}
          className="justify-content-end pr-5"
        >
          <Col
            lg={{ span: 5 }}
            style={{ ...s.text, paddingTop: "6em", paddingBottom: "6em" }}
          >
            <section>
              <p style={{ ...s.title }}>Tech for Good</p>

              <p>
                Wir wollen Technologie für Gutes einsetzen. Deshalb haben wir
                maracuja mit Blick auf die Menschheit gebaut.
              </p>

              <p>
                Unsere Mission: Wir wollen das Beste aus der digitalen
                Technologie herausholen, um unser Leben offline zu bereichern!{" "}
              </p>

              <p>
                Unsere erste Social Life App basiert auf der Idee, eine bessere
                Gesellschaft zu schaffen. Eine Gesellschaft, in der sich die
                Menschen tief mit ihren Freunden und Mitmenschen verbunden
                fühlen. Bei der wir mit unseren Freunden im wirklichen Leben
                Aktivitäten genießen, die bleibende Erinnerungen schaffen.
              </p>

              <p>
                Wir stehen hinter der Tech For Good-Erklärung der kanadischen
                Tech-Community, auch wenn wir in Deutschland ansässig sind.
              </p>

              <p style={{ ...s.title, marginTop:'2em' }}>Digitale Ethik</p>

              <p>
                Wir respektieren die Privatsphäre der Menschen und halten uns
                gerne an die EU-Datenschutzgrundverordnung (DSGVO). Wir sind ein
                zweckorientiertes Unternehmen, das in erster Linie im Dienste
                unserer Nutzer steht. maracuja ist kein typisches Startup - es
                ist vielmehr eine Lebenseinstellung!
              </p>

              <p>
                Aus diesem Grund vertreten wir eine Unternehmensphilosophie, die
                auf mehr als nur gewinnorientiertes Wachstum ausgerichtet ist.
                Wenn es nicht gut für die Menschheit ist, werdet Ihr es auf
                maracuja nicht finden. Unsere Definition von Erfolg ist: Wir
                arbeiten für unsere Nutzer, um ihnen ein glückliches Leben zu
                ermöglichen. Wenn wir das erreichen können, sind wir
                erfolgreich.{" "}
              </p>
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const s = {
  title: { ...styles.montserratLight, fontSize: "1.4em", color: "white" },
  text: { ...styles.montserratRegular, fontSize: "0.9em", color: "white" },
};
