import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";

import expo41 from "../../assets/images/expo-4-2-2.png";
import expo41c from "../../assets/images/expo-4-1-caption.svg";
import expo42 from "../../assets/images/expo-4-1-2.png";
import expo42c from "../../assets/images/expo-4-2-caption.svg";
import expo43 from "../../assets/images/expo-4-3-2.png";
import expo43c from "../../assets/images/expo-4-3-caption.svg";

import colors from "../../assets/styles/colors";

import useBreakPoint from "@restart/hooks/useBreakpoint";

export default function AppEventCreation() {
  const isBig = useBreakPoint({ lg: "up" });

  return (
    <section>
      <Container style={{ marginBottom: "15em" }}>
        <Row
          className="justify-content-center"
          style={{ marginTop: "10em", marginBottom: "5em" }}
        >
          <Col className="text-center">
            <span
              style={{
                ...styles.montserratRegular,
                fontSize: "1.9em",
                color: colors.brownishGrey,
              }}
            >
              Wie erstelle ich ein eigenes Event?
            </span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col
            sm={{ span: 10 }}
            lg={{ span: 4 }}
            md={{ span: 9 }}
            style={{ height: "70vh" }}
            className="d-flex text-center align-items-center"
          >
            <img
              src={expo41c}
              style={{ width: "70%", position: "absolute", top: 0, zIndex: -1 }}
            />
            <img src={expo41} style={{ height: "70%" }} className="mx-auto" />
          </Col>
          <Col
            sm={{ span: 10 }}
            lg={{ span: 4 }}
            md={{ span: 9 }}
            style={{ height: "70vh", marginTop: isBig ? 0 : "-5em"  }}
            className="d-flex text-center align-items-center"
          >
            <img src={expo42} style={{ height: "70%" }} className="mx-auto" />
            <img
              src={expo42c}
              style={{
                width: "70%",
                position: "absolute",
                bottom: 0,
                zIndex: -1,
              }}
            />
          </Col>
          <Col
            sm={{ span: 10 }}
            lg={{ span: 4 }}
            md={{ span: 9 }}
            style={{ height: "70vh", marginTop: isBig ? 0 : "5em" }}
            className="d-flex text-center align-items-center"
          >
            <img src={expo43} style={{ height: "70%" }} className="mx-auto" />
            <img
              src={expo43c}
              style={{
                width: "70%",
                position: "absolute",
                top: 0,
                zIndex: -1,
                right: "10%",
              }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const s = {
  introContainer: {
    borderTopRightRadius: 160,
    overflow: "hidden",
    ...styles.shadowLight,
  },
  introTextBox: {
    display: "flex",
    height: "15em",
    paddingLeft: "3em",
    paddingRight: "3em",
    ...styles.montserratRegular,
    fontSize: "1em",
    color: "white",
  },

  //   Description Cards
  cardImage: { ...styles.shadowLight, width: "100%" },
  cardTextBox: {
    ...styles.bg_purple,
    ...styles.montserratLight,
    fontSize: "0.8em",
    display: "flex",
    height: "9em",
    paddingTop: "1.3em",
    paddingRight: "0.8em",
    paddingLeft: "1.3em",
    color: "white",
    borderBottomLeftRadius: 43,
  },
};
