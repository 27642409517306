import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import styles from "../assets/styles/module.styles";
import bg from "../assets/images/fresh-jumbo.jpg";

import Footer from "../components/shared/footer";
import colors from "../assets/styles/colors";
import Jumbotron from "../components/jumpbotron";

const Content = () => {
  return (
    <Container>
      <Row
        className="justify-content-center"
        style={{ paddingBottom: "6em", paddingTop: "5em" }}
      >
        <Col lg={{ span: 10 }} style={s.text}>
          <p style={s.title}>Kommt bald</p>
        </Col>
      </Row>
    </Container>
  );
};

export default function FreshView() {
  return (
    <div style={{ flex: 1 }}>
      <Jumbotron image={bg} height="80vh" className="align-items-end">
        <Container>
          <Row className="justify-content-left">
            <Col className="offset-1">
              <span
                style={{
                  ...styles.montserratLight,
                  fontSize: "1.4em",
                  color: "white",
                }}
              >
                frisch gepresst
              </span>
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <Content />

      <Footer color={colors.orchid} />
    </div>
  );
}

const s = {
  title: {
    ...styles.montserratSemiBold,
    color: colors.orchid,
    fontSize: "0.95em",
  },
  subtext: {
    ...styles.montserratRegular,
    color: colors.orchid,
    fontSize: "0.9em",
    lineHeight: "1.7em",
  },
  text: {
    ...styles.montserratRegular,
    color: colors.brownishGrey,
    fontSize: "0.9em",
    lineHeight: "1.7em",
  },
};
