import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import styles from "../assets/styles/module.styles";
import bg from "../assets/images/team-bg.jpg";
import TeamJumbotron from "../components/team/TeamJumbotron";
import TeamMembers from "../components/team/TeamMembers";
import TeamContactForm from "../components/team/TeamContactForm";
import Footer from "../components/shared/footer";
import colors from "../assets/styles/colors";
import Jumbotron from "../components/jumpbotron";

const TeamView = () => {
  return (
    <div style={{ flex: 1 }}>
      <Jumbotron image={bg} className="align-items-end" />
      {/* Membmers */}
      <Container>
        <Row className="justify-content-center text-center" style={{paddingTop:'3em', paddingBottom:'4em'}}>
          <Col lg={{ span: 9 }}>
            <p
              style={{
                ...styles.montserratRegular,
                fontSize: "1em",
                color: colors.brownishGrey,
              }}
            >
              Unser Team ist begeistert von Freundschaften und hilft anderen,
              ihre Freundschaften zu pflegen. Alle unsere strategischen
              Entscheidungen basieren auf der Frage:
            </p>
            <p
             style={{
                ...styles.montserratRegular,
                fontSize: "1em",
                color: colors.goldenYellow,
              }}
            >
              Wie helfen sie den Menschen, ein glückliches und erfülltes Leben
              zu führen?
            </p>
          </Col>
        </Row>
      </Container>
      <TeamMembers />
      {/* Contact Form */}
      <Container>
        <Row className="justify-content-center">
          <Col
            lg={{ span: 8 }}
            style={{
              ...styles.montserratRegular,
              color: colors.goldenYellow,
              fontSize: "0.9em",
              marginTop: "4em",
              marginBottom: "4em",
            }}
            className="text-center"
          >
            <p>
              Schreib uns einfach eine E-Mail an hello@maracuja.app oder nutze
              das Kontaktformular.
            </p>
          </Col>
        </Row>
      </Container>
      <TeamContactForm />
      {/* Footer */}
      <Footer color={colors.goldenYellow} />
    </div>
  );
};

export default TeamView;
