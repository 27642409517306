import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import styles from "../../assets/styles/module.styles";
import iosButton from "../../assets/images/kindpng_2552712.png";
import androidButton from "../../assets/images/kindpng_255271.png";
import logo from "../../assets/images/maracuja logo top.png";
import Jumbotron from "../jumpbotron";
import colors from "../../assets/styles/colors";

import vadimImg from "../../assets/images/team-vadim.jpg";
import valeriaImg from "../../assets/images/team-veleria.jpg";
import ninaImg from "../../assets/images/team-nina.jpg";
import jimmyImg from "../../assets/images/team-jimmy.png";

export default function TeamMembers() {
  const members = [
    {
      image: vadimImg,
      title: "Vadim Eberle - Founder & CEO",
      email: "vadim.eberle@maracuja.de",
      color: colors.orchid,
      phone: "0177 767 486 13",
      text:
        "Vadim treibt die strategische Vision von maracuja voran und verwaltet die Investor Relations.",
    },
    {
      image: valeriaImg,
      title: "Valeria Memcova - Management",
      // email: "vadim.eberle@maracuja.de",
      // phone: "0177 767 486 13",
      color: colors.coralPink,

      text:
        "Valeria konzentriert sich auf das Management wirklich effizienter Prozesse.",
    },
    {
      image: jimmyImg,
      title: "Jimmy Li - Software",
      color: colors.goldenYellow,

      text:
        "Jimmy überträgt die Designsprache von Architekturkonzepten in die digitale Welt.",
    },
    {
      image: ninaImg,
      color: colors.orchid,

      title: "Nina Grenningloh Reyes - Brand Communications",
      text:
        "Nina erzählt die Geschichte von maracuja und belebt diese immer wieder neu.",
    },
  ];

  const renderItem = ({ image, title, color, text, phone, email }, index) => {
    const isOdd = index % 2 === 0;
    return (
      <Row
        style={{
          height: "30vh",
          marginBottom: "2.3em",
          borderTopLeftRadius: isOdd ? 60 : 0,
          borderBottomLeftRadius: isOdd ? 0 : 60,
          overflow: "hidden",
          ...styles.shadowMedium,
        }}
      >
        <Col
          style={{
            backgroundImage: `url(${image})`,
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "cover",
          }}
          className="p-0"
        >
          <Row
            style={{
              height: "100%",
            }}
          >
            <Col className="align-self-end">
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  // backdropFilter: "blur(0px)",
                }}
                className={`pl-${isOdd ? "4" : "5"} py-3`}
              >
                <span
                  style={{
                    ...styles.montserratSemiBold,
                    color: "white",
                    fontSize: "0.7em",
                  }}
                >
                  {title}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col style={{ backgroundColor: color }} className="pt-5 pl-4">
          <span
            style={{
              ...styles.montserratRegular,
              color: "white",
              fontSize: "1.1em",
            }}
          >
            {text}
          </span>
        </Col>
      </Row>
    );
  };

  return (
    <section>
      <Container>
        <Row className="justify-content-center">
          <Col lg={{ span: 8 }}>{members.map(renderItem)}</Col>
        </Row>
      </Container>
    </section>
  );
}
